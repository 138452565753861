import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Button,
    Form,
    ListGroup,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap'

import { faDownload } from '@fortawesome/pro-duotone-svg-icons/faDownload'
import { faFile } from '@fortawesome/pro-duotone-svg-icons/faFile'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus'
import { faSignature } from '@fortawesome/pro-duotone-svg-icons/faSignature'
import { faUnlink } from '@fortawesome/pro-duotone-svg-icons/faUnlink'

import NewFile from '../Admin/Pages/Files/NewFile'
import ConfirmDeleteDialog from '../Form/ConfirmDeleteDialog'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import Signature from '../../feature/DisplayItems/FileDrawableField/index.tsx'

const FilePreviewModal = React.lazy(() =>
    import('../Elements/Modals/FilePreviewModal.tsx')
)

class FileField extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        this.state = {
            signing: false,
            deleteFile: false,
            value:
                this.props.value && Array.isArray(this.props.value)
                    ? this.props.value
                    : [],
            dataSignature: '',
        }

        this.addFile = this.addFile.bind(this)
        this.renderDeleteModal = this.renderDeleteModal.bind(this)
        this.doPreview = this.doPreview.bind(this)
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1)
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true
        }

        return false
    }

    getValue() {
        if (!this.state.value) {
            return []
        }

        return this.state.value.map((item) => item.id)
    }

    hasLimitations() {
        return this.props.item?.settings?.file_types ? true : false
    }

    getLimitations() {
        const fileTypes = this.props.item?.settings?.file_types
        return Array.isArray(fileTypes) ? fileTypes : []
    }

    showPreview() {
        if (!this.props.item || !this.props.item.settings) {
            return false
        }
        if (this.props.item.settings.file_preview === true) {
            return true
        }

        return false
    }

    showDirect() {
        if (!this.props.item || !this.props.item.settings) {
            return false
        }
        if (this.props.item.settings.preview_direct === true) {
            return true
        }

        return false
    }

    isDocument() {
        if (!this.hasLimitations()) {
            return false
        }

        return this.getLimitations().includes('documents')
    }

    getMaxWidth() {
        if (
            !this.props.item ||
            !this.props.item.settings ||
            !this.props.item.settings.preview_width
        ) {
            return '200px'
        }
        if (this.props.item.settings.preview_width.length < 0) {
            return '200px'
        }

        return this.props.item.settings.preview_width
    }

    getPreviewHeight() {
        if (
            !this.props.item ||
            !this.props.item.settings ||
            !this.props.item.settings.preview_height
        ) {
            return '200px'
        }
        if (this.props.item.settings.preview_height.length < 0) {
            return '200px'
        }

        return this.props.item.settings.preview_height
    }

    isImage(file) {
        if (file && file.mime.includes('image')) return true
    }

    doPreview(file) {
        if (!file) return

        return this.showDirect() && this.isImage(file) ? (
            <img
                style={{
                    maxHeight: this.getPreviewHeight(),
                    maxWidth: this.getMaxWidth(),
                }}
                className={'float-right img-fluid img-thumbnail'}
                src={this.getDownloadUrlById(file.id)}
            />
        ) : (
            <FilePreviewModal
                kedo={this.props.kedo}
                files={this.state.value}
                currentIndex={this.state.value.findIndex(
                    (item) => item.id === file.id
                )}
                showButton={true}
            />
        )
    }

    renderShow() {
        if (!this.props.value) {
            return ''
        }

        if (!Array.isArray(this.props.value)) {
            return ''
        }

        let showPreview = this.showPreview()

        return this.state.value.length <= 0 ? (
            <span className={'text-muted'}>
                {this.props.kedo.t('No files found')}
            </span>
        ) : (
            <ListGroup as="ul" variant="flush">
                {this.state.value.map((file) => (
                    <ListGroup.Item key={`fg-${file.id}`}>
                        <FontAwesomeIcon icon={faFile} /> {file.filename}{' '}
                        {file.description &&
                        (file.description > 0 || file.description !== null) ? (
                            <span className={'text-muted small'}>
                                ({file.description})
                            </span>
                        ) : null}
                        <div className={'float-right'}>
                            {this.isDocument() ? (
                                <Button
                                    onClick={() =>
                                        this.setState({ signing: true })
                                    }
                                    className={'outline-secondary'}
                                    size={'sm'}
                                >
                                    <FontAwesomeIcon icon={faSignature} />{' '}
                                    {this.state.signing ? (
                                        <LoadingDefault />
                                    ) : null}
                                </Button>
                            ) : null}

                            {showPreview ? this.doPreview(file) : null}

                            <a
                                className={'btn btn-outline-secondary btn-sm'}
                                title={this.props.kedo.t('Download item')}
                                target={'_new'}
                                href={this.getDownloadUrlById(file.id)}
                                style={{ border: 'none' }}
                            >
                                <FontAwesomeIcon
                                    onClick={(e) => e.stopPropagation()}
                                    icon={faDownload}
                                />
                            </a>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        )
    }

    onChange(event) {
        this.setState({ value: event.target.value })
        if (this.props.changeValue) {
            this.props.changeValue(this.props.item.id, event.target.value)
        }
    }

    addFile(file) {
        let value = this.state.value
        value.push(file)

        this.setState({
            showUpload: false,
            value: value,
        })
    }

    getDownloadUrlById(fileId) {
        let baseUrl = this.props.kedo.api().getBaseUrl()

        return `${baseUrl}/file/${fileId}/download`
    }

    renderDeleteModal(fileId) {
        let url = this.props.kedo.api().getFileEndpoint()
        if (this.props.dossier && this.props.dossier.id) {
            url = url + '/' + fileId + '/detach/' + this.props.dossier.id
        } else {
            url = url + '/' + fileId
        }

        let values = this.state.value

        return (
            <ConfirmDeleteDialog
                onSuccess={() => {
                    this.setState({
                        deleteFile: false,
                        value: values.filter((item) => item.id !== fileId),
                    })
                }}
                kedo={this.props.kedo}
                onClose={() => {
                    this.setState({ deleteFile: false })
                }}
                url={url}
            />
        )
    }

    handleSaveSignature = (dataURL) => {
        this.setState({ showUpload: true, dataSignature: dataURL })
    }

    renderEdit() {
        const kedo = this.props.kedo

        return (
            <div ref={this.innerRef}>
                {this.state.deleteFile
                    ? this.renderDeleteModal(this.state.deleteFile)
                    : null}

                {this.state.showUpload ? (
                    <NewFile
                        onClose={() => this.setState({ showUpload: false })}
                        accepts={
                            this.hasLimitations() ? this.getLimitations() : null
                        }
                        onSuccess={this.addFile}
                        kedo={kedo}
                        dataSignature={this.state.dataSignature}
                    />
                ) : null}
                {this.props.item.settings?.signature ? (
                    <Signature onSave={this.handleSaveSignature} />
                ) : (
                    <Button
                        title={kedo.t('Upload file')}
                        variant={
                            this.isInvalid()
                                ? 'outline-danger'
                                : 'outline-secondary'
                        }
                        onClick={() => this.setState({ showUpload: true })}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp; {kedo.t('Upload file')}
                    </Button>
                )}
                <br />
                <br />
                {this.state.value.length <= 0 ? (
                    <span className={'text-muted'}>
                        {kedo.t('No files found')}
                    </span>
                ) : (
                    <ListGroup as={'ul'} variant="flush">
                        {this.state.value.map((file) => (
                            <ListGroup.Item
                                key={`${this.props.item.id}-f-${file.id}`}
                            >
                                <FontAwesomeIcon icon={faFile} />{' '}
                                {file.filename}{' '}
                                {file.description &&
                                (file.description > 0 ||
                                    file.description !== null) ? (
                                    <span className={'text-muted small'}>
                                        ({file.description})
                                    </span>
                                ) : null}
                                <span className={'float-right'}>
                                    <a
                                        title={kedo.t('Download item')}
                                        target={'_new'}
                                        href={this.getDownloadUrlById(file.id)}
                                        style={{ border: 'none' }}
                                    >
                                        <Button
                                            variant={'outline-secondary'}
                                            size={'sm'}
                                        >
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                            />
                                        </Button>
                                    </a>{' '}
                                    &nbsp;
                                    {kedo.isAdminOrEnvironmentAdmin() ||
                                    kedo
                                        .env()
                                        .hasCredential('kedo.file.delete') ? (
                                        <Button
                                            title={kedo.t('Detach')}
                                            onClick={() =>
                                                this.setState({
                                                    deleteFile: file.id,
                                                })
                                            }
                                            size={'sm'}
                                            variant={'outline-danger'}
                                        >
                                            <FontAwesomeIcon icon={faUnlink} />
                                        </Button>
                                    ) : null}
                                </span>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}

                {this.isInvalid() ? (
                    <div className="invalid-feedback d-block">
                        {this.props.errors}
                    </div>
                ) : null}
            </div>
        )
    }

    render() {
        if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            if (this.state.value !== this.props.value) {
                this.setState({ value: this.props.value })
            }
            return (
                <Form.Control
                    onChange={(event) =>
                        this.props.changeValue(
                            this.props.item.id,
                            event.target.value
                        )
                    }
                    onKeyPress={
                        this.props.onKeyPress
                            ? (event) =>
                                  this.props.onKeyPress(
                                      this.props.item.id,
                                      event
                                  )
                            : null
                    }
                    type="text"
                    value={this.props.value}
                />
            )
        } else if (
            this.props.mode === 'edit' ||
            this.props.mode === 'show/edit'
        ) {
            return this.renderEdit()
        } else if (
            this.props.mode === 'list' &&
            this.showPreview() === true &&
            this.showDirect() === true
        ) {
            if (!this.props.value) {
                return ''
            }

            if (
                !Array.isArray(this.props.value) ||
                this.props.value.length <= 0
            ) {
                return ''
            }
            return this.state.value.map((file) => (
                <img
                    key={file.id}
                    style={{
                        maxHeight: this.getPreviewHeight(),
                        maxWidth: this.getMaxWidth(),
                    }}
                    className={'img-fluid img-thumbnail'}
                    src={this.getDownloadUrlById(file.id)}
                />
            ))
        }

        return this.renderShow()
    }
}

export default FileField
