import React, { Component } from 'react'
import {
    Accordion,
    Button,
    Card,
    Col,
    Form,
    Modal,
    Nav,
    Row,
    Tab,
    InputGroup,
} from 'react-bootstrap'
import LoadingDefault from '../../../Elements/Loading/LoadingDefault'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus'
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons/faTrashAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleName from '../../../Elements/Translations/LocaleName'
import SelectList from '../../../Elements/SelectList'
import DisplayCustomViewForm from '../../../Form/DisplayCustomViewForm'
import NivoSchemeSelector from '../../../Form/NivoSchemeSelector'
import BlockFormTemplateSelect from './BlockFormTemplateSelect'
import BlockFormPercentageChartSetting from './BlockFormPercentageChartSetting'
import BlockTabQueries from './BlockTabQueries'
import BlockSortSettings from './BlockSortSettings'
import ObjectCreatedInfo from '../../../Elements/ObjectCreatedInfo'
import DossierQuerySelect from '../../../Elements/Select/DossierQuerySelect'
import ConfirmDeleteDialog from '../../../Form/ConfirmDeleteDialog'

class BlockForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            defDossiers: [],
            dossierQuery: false,
            countLinkDefDossier: null,
            countLinkDefDossierSec: null,
            dossierQueries: [],
            delete: false,
            errors: [],
            views: [],
            sort:
                this.props.item &&
                this.props.item.settings &&
                this.props.item.settings.sort
                    ? this.props.item.settings.sort
                    : null,
            view_name:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.view_name
                    : 'list',
            authKey:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.authKey
                    : null,
            authToken:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.authToken
                    : null,
            dashboardId:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.dashboardId
                    : null,
            showAdd:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.show_add
                    : false,
            tabQueries:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.tab_queries
                    : false,
            template:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.template
                    : null,
            templateOptions:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.templateOptions
                    : {},
            pie_charts:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.pie_charts
                    : null,
            list_field:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.list_field
                    : null,
            count_list_field:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.count_list_field
                    : null,
            count_link_field:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.count_link_field
                    : null,
            count_link_field_sec:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.count_link_field_sec
                    : null,
            link_field:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.link_field
                    : null,
            origin_link_field:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.origin_link_field
                    : null,
            scheme:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.scheme
                    : null,
            date_field:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.date_field
                    : null,
            sum_field:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.sum_field
                    : null,
            limit:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.limit
                    : 10,
            carousel:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.carousel
                    : false,
            use_creation_date:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.use_creation_date
                    : false,
            group_format:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.group_format
                    : null,
            date_format:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.date_format
                    : null,
            chart_format:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.chart_format
                    : null,
            chart_layout:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.chart_layout
                    : null,
            table_layout:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.table_layout
                    : null,
            reverse_axis:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.reverse_axis
                    : null,
            show_y_scroll:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.show_y_scroll
                    : null,
            enable_labels:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.enable_labels
                    : null,
            enable_grid:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.enable_grid
                    : null,
            origin_display_field:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.origin_display_field
                    : null,
            link_display_field:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.link_display_field
                    : null,
            legend_direction:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.legend_direction
                    : null,
            layout:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.layout
                    : 'table',
            period:
                this.props.item && this.props.item.settings
                    ? this.props.item.settings.period
                    : null,
            availableRoles: [],
            parent: this.props.item ? this.props.item.parent_id : null,
            item: this.props.item
                ? {
                      id: this.props.item.id,
                      translations: this.props.item.translations,
                      defDossier: this.props.item.def_dossier_id,
                      dossierQuery: this.props.item.dossier_query_id,
                      roles: this.props.item.roles.map((item) => item.role_id),
                      col: this.props.item.col,
                      rank: this.props.item.rank,
                      dashboard: this.props.item.dashboard,
                      type: this.props.item.type,
                      settings: this.props.item.settings
                          ? this.props.item.settings
                          : {},
                  }
                : {
                      type: 'dashboard',
                      col: 0,
                      rank: 0,
                      defDossier: null,
                      translations: [],
                      roles: [],
                      dashboard: true,
                      settings: {},
                  },
            submitting: false,
        }

        this.handleErrors = this.handleErrors.bind(this)
        this.handleSuccess = this.handleSuccess.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleTemplateOptionsChange =
            this.handleTemplateOptionsChange.bind(this)
        this.handleNormalChange = this.handleNormalChange.bind(this)
        this.handleChangeVar = this.handleChangeVar.bind(this)
        this.onChangeTranslation = this.onChangeTranslation.bind(this)
        this.submit = this.submit.bind(this)
        this.onChangeRole = this.onChangeRole.bind(this)
        this.renderSettingsByType = this.renderSettingsByType.bind(this)
    }

    onChangeTranslation(locale, title) {
        let myPageBlock = this.state.item

        if (!myPageBlock.translations || myPageBlock.translations.length <= 0) {
            myPageBlock.translations = []
        }

        let trans = myPageBlock.translations.find(
            (item) => item.culture === locale
        )
        let translations = myPageBlock.translations.filter(
            (item) => item.culture !== locale
        )

        myPageBlock.translations = translations
        if (!trans) {
            trans = { culture: locale, title: title }
        } else {
            trans.title = title
        }
        translations.push(trans)
        myPageBlock.translations = translations

        this.setState({ item: myPageBlock })
    }

    /**
     * @param response
     * @returns {boolean}
     */
    hasFormErrors(response) {
        if (
            response.response &&
            response.response.status === 400 &&
            response.response.data &&
            response.response.data.errors
        ) {
            return true
        }

        return false
    }

    fetchRoles() {
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        api.get(api.getRoleEndpoint(), {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 200,
            },
        }).then((response) =>
            this.setState({
                loading: false,
                availableRoles: response.data.results,
            })
        )
    }

    fetchCustomViews() {
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        api.get(api.getDisplayCustomViewEndpoint(), {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                defDossier: this.state.item.defDossier,
                limit: 200,
            },
        }).then((response) =>
            this.setState({
                loading: false,
                views: response.data.results,
            })
        )
    }

    fetchQueries() {
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        api.get(api.getDossierQueryEndpoint(), {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                defDossier: this.state.item.defDossier,
                limit: 200,
            },
        }).then((response) =>
            this.setState({
                loading: false,
                dossierQueries: response.data.results,
            })
        )
    }

    fetchDefDossiers() {
        const api = this.props.kedo.api()
        this.setState({ loading: true })
        api.getCached(api.getDefDossierEndpoint(), {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 200,
            },
        }).then((response) =>
            this.setState({
                loading: false,
                defDossiers: response.data.results,
            })
        )
    }

    handleSuccess() {
        this.setState({
            submitting: false,
            item: {},
            errors: false,
        })
        this.props.onSuccess(true)
    }

    handleErrors(response) {
        if (!this.hasFormErrors(response)) {
            this.setState({
                submitting: false,
                errors: {
                    generic: this.props.kedo.t('Something went wrong'),
                },
            })
        }
        this.setState({
            submitting: false,
            errors: response.response.data.errors,
        })
    }

    renderErrorFeedback(errorMsg) {
        if (!errorMsg) {
            return
        }

        return (
            <Form.Control.Feedback type="invalid">
                {errorMsg}
            </Form.Control.Feedback>
        )
    }

    handleNormalChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleChangeVar(key, val) {
        this.setState({ [key]: val })
    }

    handleTemplateOptionsChange(fieldName, fieldValue) {
        let templateOptions = this.state.templateOptions
        if (!templateOptions) {
            templateOptions = {}
        }
        templateOptions[fieldName] = fieldValue
        this.setState({ templateOptions: templateOptions })
    }

    handleChange(event) {
        let item = this.state.item
        item[event.target.name] = event.target.value
        this.setState({ item: item })
    }

    submit() {
        const kedo = this.props.kedo

        if (
            !this.state.item.type ||
            (![
                'online_users',
                'funnel',
                'active_users',
                'new_dossier_statistics',
                'block_container',
            ].includes(this.state.item.type) &&
                !this.state.item.defDossier)
        ) {
            let errors = {}
            if (!this.state.item.type || this.state.item.type.length <= 0) {
                errors.type = kedo.t('This is a required field')
            }
            if (
                !this.state.item.defDossier ||
                this.state.item.defDossier.length <= 0
            ) {
                errors.defDossier = kedo.t('This is a required field')
            }

            this.setState({ errors: errors })
            return
        }

        this.setState({
            submitting: true,
            errors: false,
        })

        let baseUrl = kedo.api().getMyPageBlockEndpoint()
        let settings = {
            limit: this.state.limit,
            scheme: this.state.scheme,
        }

        if (this.state.item.type === 'dashboard') {
            settings.view_name = this.state.view_name
            settings.layout = this.state.layout
            settings.show_add = this.state.showAdd
            settings.tab_queries = this.state.tabQueries
            settings.carousel = this.state.carousel
            settings.template = this.state.template
            settings.sort = this.state.sort
            settings.templateOptions = this.state.templateOptions
        } else if (
            this.state.item.type === 'list_option_percentage_conditions'
        ) {
            settings.pie_charts = this.state.pie_charts
        } else if (this.state.item.type === 'count_list_options') {
            settings.count_list_field = this.state.count_list_field
            settings.date_field = this.state.date_field
            settings.use_creation_date = this.state.use_creation_date
            settings.date_format = this.state.date_format
            settings.chart_format = this.state.chart_format
            settings.group_format = this.state.group_format
            settings.chart_layout = this.state.chart_layout
            settings.table_layout = this.state.table_layout
            settings.reverse_axis = this.state.reverse_axis
            settings.show_y_scroll = this.state.show_y_scroll
            settings.enable_labels = this.state.enable_labels
            settings.enable_grid = this.state.enable_grid
            settings.legend_direction = this.state.legend_direction
            settings.scheme = this.state.scheme
        } else if (this.state.item.type === 'sum_fields') {
            settings.sum_field = this.state.sum_field
            settings.scheme = this.state.scheme
        } else if (this.state.item.type === 'block_container') {
            settings.layout =
                !this.state.layout || this.state.layout === 'table'
                    ? 'horizontal'
                    : this.state.layout
        } else if (this.state.item.type === 'date_statistics') {
            settings.scheme = this.state.scheme
            settings.link_field = this.state.link_field
            settings.list_field = this.state.list_field
            settings.period = this.state.period
            settings.date_field = this.state.date_field
        } else if (this.state.item.type === 'count_link_options') {
            settings.date_field = this.state.date_field
            settings.date_format = this.state.date_format
            settings.use_creation_date = this.state.use_creation_date
            settings.chart_format = this.state.chart_format
            settings.group_format = this.state.group_format
            settings.chart_layout = this.state.chart_layout
            settings.table_layout = this.state.table_layout
            settings.reverse_axis = this.state.reverse_axis
            settings.link_display_field = this.state.link_display_field
            settings.count_link_field_sec = this.state.count_link_field_sec
            settings.origin_link_field = this.state.origin_link_field
            settings.show_y_scroll = this.state.show_y_scroll
            settings.origin_display_field = this.state.origin_display_field
            settings.count_link_field = this.state.count_link_field
            settings.scheme = this.state.scheme
        } else if (this.state.item.type === 'geomap') {
            //TODO
        } else if (this.state.item.type === 'funnel') {
            //TODO
        } else {
            settings.scheme = this.state.scheme
        }

        let item = {
            translations: this.state.item.translations,
            settings: settings,
            parent: this.state.parent,
            type: this.state.item.type,
            col: this.state.item.col,
            defDossier: this.state.item.defDossier,
            dashboard: this.state.item.dashboard,
            rank: this.state.item.rank,
            dossierQuery: this.state.item.dossierQuery,
            roles: this.state.item.roles,
        }
        if (this.state.item.id) {
            kedo.api()
                .patch(baseUrl + '/' + this.props.item.id, item)
                .then(() => this.handleSuccess())
                .catch((errResponse) => this.handleErrors(errResponse))
        } else {
            item.environment = kedo.env().getEnvironment().id
            item.uuid = kedo.utils().uuidv4()

            kedo.api()
                .post(baseUrl, item)
                .then(() => this.handleSuccess())
                .catch((errResponse) => this.handleErrors(errResponse))
        }
    }

    onChangeRole(e) {
        let item = this.state.item
        item.roles = item.roles.filter((roleId) => e.target.value != roleId)

        if (e.target.checked) {
            item.roles.push(parseInt(e.target.value))
        }

        this.setState({ item: item })
    }

    renderTranslations(translations) {
        if (!translations || translations.length <= 0) {
            translations = []
        }
        let locales = this.props.kedo.env().getEnvironmentLocales()

        if (!locales) {
            return this.props.kedo.t('No languages set')
        }

        if (locales.length === 1) {
            let trans = translations.find(
                (item) => locales[0].code === item.culture
            )
            if (!trans) {
                trans = {}
            }

            return (
                <div>
                    <Form.Group>
                        <Form.Label>{this.props.kedo.t('Name')}</Form.Label>
                        <Form.Control
                            value={trans.title}
                            type={'text'}
                            onChange={(event) =>
                                this.onChangeTranslation(
                                    locales[0].code,
                                    event.target.value
                                )
                            }
                        />
                    </Form.Group>
                </div>
            )
        }

        return (
            <Accordion
                defaultActiveKey={locales[0].id}
                className={'language_accordion'}
            >
                {locales.map((locale) => {
                    let trans = translations.find(
                        (item) => locale.code === item.culture
                    )
                    if (!trans) {
                        trans = {}
                    }
                    return (
                        <Card key={locale.id}>
                            <Accordion.Toggle
                                as={Card.Header}
                                eventKey={locale.id}
                            >
                                <LocaleName
                                    kedo={this.props.kedo}
                                    localeCode={locale.code}
                                />
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={locale.id}>
                                <Card.Body>
                                    <Form.Group style={{ marginBottom: 0 }}>
                                        <Form.Control
                                            value={trans.title}
                                            type={'text'}
                                            onChange={(event) =>
                                                this.onChangeTranslation(
                                                    locale.code,
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                })}
            </Accordion>
        )
    }

    componentDidMount() {
        this.fetchDefDossiers()
        this.fetchRoles()
        this.fetchQueries()
        if (this.props.item) {
            this.fetchCustomViews()
        }
    }

    renderSettingsByType(blockType) {
        const kedo = this.props.kedo

        if (blockType === 'list_option_percentage_conditions') {
            if (!this.state.item.defDossier) {
                return ''
            }
            return (
                <>
                    <BlockFormPercentageChartSetting
                        pie_charts={this.state.pie_charts}
                        kedo={kedo}
                        onChange={(pieCharts) =>
                            this.setState({ pie_charts: pieCharts })
                        }
                    />
                    <NivoSchemeSelector
                        kedo={kedo}
                        value={this.state.scheme}
                        onChange={(e) =>
                            this.setState({ scheme: e.target.value })
                        }
                    />
                </>
            )
        } else if (blockType === 'count_link_options') {
            if (!this.state.item.defDossier) {
                return ''
            }
            return (
                <div>
                    <h5>{kedo.t('Date options')}</h5>
                    <Form.Group>
                        <Form.Label>
                            {kedo.t('Date')} <small>{kedo.t('Optional')}</small>
                        </Form.Label>
                        <SelectList
                            disableInit={true}
                            currentValue={this.state.date_field}
                            onClear={() => this.setState({ date_field: null })}
                            searchName={'search'}
                            kedo={this.props.kedo}
                            urlParams={{
                                type: 'date',
                                environment: kedo.env().getEnvironment().id,
                                defDossier: this.state.item.defDossier,
                            }}
                            onDisplayName={(item) => item.name}
                            url={kedo.api().getDefDossierDefFieldEndpoint()}
                            onSelect={(item) => {
                                // let settings = this.state.item.settings;
                                // settings.date_field = item.id;
                                this.setState({ date_field: item.id })
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {kedo.t('Use dossier creation date')}{' '}
                            <small>{kedo.t('Optional')}</small>
                        </Form.Label>
                        <Form.Check
                            value={true}
                            checked={this.state.use_creation_date === true}
                            onChange={(e) => {
                                this.setState({
                                    use_creation_date: e.target.checked,
                                })
                            }}
                            type={'checkbox'}
                            id={`use_creation_date`}
                        />
                    </Form.Group>
                    <hr />
                    <h5>{kedo.t('Origin options')}</h5>
                    <Form.Group>
                        <Form.Label>
                            {kedo.t('Origin display link field')}
                        </Form.Label>
                        <SelectList
                            key={`origin_link_field`}
                            disableInit={true}
                            currentValue={this.state.origin_link_field}
                            onClear={() =>
                                this.setState({ origin_link_field: null })
                            }
                            searchName={'search'}
                            kedo={kedo}
                            urlParams={{
                                page: 0,
                                limit: 200,
                                environment: kedo.env().getEnvironment().id,
                                defDossier: this.state.item.defDossier,
                            }}
                            onDisplayName={(item) => item.name}
                            url={kedo.api().getDefDossierLinkEndpoint()}
                            onUpdateCurrentItem={(item) =>
                                this.setState({
                                    originLinkDefDossier:
                                        item.child_def_dossier_id,
                                })
                            }
                            onSelect={(item) => {
                                this.setState({
                                    origin_link_field: item.id,
                                    originLinkDefDossier:
                                        item.child_def_dossier_id,
                                })
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {kedo.t('Origin display field')}
                        </Form.Label>
                        <SelectList
                            key={`link_display_field`}
                            currentValue={this.state.origin_display_field}
                            onClear={() =>
                                this.setState({ origin_display_field: null })
                            }
                            disableInit={true}
                            searchName={'search'}
                            kedo={kedo}
                            urlParams={{
                                defDossier: this.state.originLinkDefDossier
                                    ? this.state.originLinkDefDossier
                                    : this.state.item.defDossier,
                            }}
                            onDisplayName={(item) => item.name}
                            url={kedo.api().getDefDossierDefFieldEndpoint()}
                            onSelect={(item) =>
                                this.setState({ origin_display_field: item.id })
                            }
                        />
                    </Form.Group>
                    <hr />
                    <h5>{kedo.t('Link options')}</h5>
                    <Form.Group>
                        <Form.Label className={'required'}>
                            {kedo.t('Link field')}
                        </Form.Label>
                        <SelectList
                            key={`count_link_field`}
                            currentValue={this.state.count_link_field}
                            onClear={() =>
                                this.setState({ count_link_field: null })
                            }
                            searchName={'search'}
                            kedo={kedo}
                            urlParams={{
                                page: 0,
                                limit: 200,
                                environment: kedo.env().getEnvironment().id,
                                defDossier: this.state.item.defDossier,
                            }}
                            onDisplayName={(item) => item.name}
                            url={kedo.api().getDefDossierLinkEndpoint()}
                            onUpdateCurrentItem={(item) =>
                                this.setState({
                                    countLinkDefDossier:
                                        item.child_def_dossier_id,
                                })
                            }
                            onSelect={(item) => {
                                this.setState({
                                    count_link_field: item.id,
                                    countLinkDefDossier:
                                        item.child_def_dossier_id,
                                })
                            }}
                        />
                    </Form.Group>
                    {this.state.countLinkDefDossier ? (
                        <Form.Group>
                            <Form.Label>
                                {kedo.t('Second link field')}
                            </Form.Label>
                            <SelectList
                                key={`count_link_field_sec`}
                                disableInit={true}
                                currentValue={this.state.count_link_field_sec}
                                onClear={() =>
                                    this.setState({
                                        count_link_field_sec: null,
                                    })
                                }
                                searchName={'search'}
                                kedo={kedo}
                                urlParams={{
                                    page: 0,
                                    limit: 200,
                                    environment: kedo.env().getEnvironment().id,
                                    defDossier: this.state.countLinkDefDossier,
                                }}
                                onDisplayName={(item) => item.name}
                                url={kedo.api().getDefDossierLinkEndpoint()}
                                onUpdateCurrentItem={(item) =>
                                    this.setState({
                                        countLinkDefDossierSec:
                                            item.child_def_dossier_id,
                                    })
                                }
                                onSelect={(item) => {
                                    this.setState({
                                        count_link_field_sec: item.id,
                                        countLinkDefDossierSec:
                                            item.child_def_dossier_id,
                                    })
                                }}
                            />
                        </Form.Group>
                    ) : null}
                    <NivoSchemeSelector
                        kedo={kedo}
                        value={this.state.scheme}
                        onChange={(e) =>
                            this.setState({ scheme: e.target.value })
                        }
                    />
                    {this.state.countLinkDefDossier ||
                    this.state.countLinkDefDossierSec ? (
                        <Form.Group>
                            <Form.Label>
                                {kedo.t('Link display field')}
                            </Form.Label>
                            <SelectList
                                key={`link_display_field`}
                                currentValue={this.state.link_display_field}
                                onClear={() =>
                                    this.setState({ link_display_field: null })
                                }
                                disableInit={true}
                                searchName={'search'}
                                kedo={kedo}
                                urlParams={{
                                    defDossier: this.state
                                        .countLinkDefDossierSec
                                        ? this.state.countLinkDefDossierSec
                                        : this.state.countLinkDefDossier,
                                }}
                                onDisplayName={(item) => item.name}
                                url={kedo.api().getDefDossierDefFieldEndpoint()}
                                onSelect={(item) =>
                                    this.setState({
                                        link_display_field: item.id,
                                    })
                                }
                            />
                        </Form.Group>
                    ) : null}
                    <hr />
                    <Form.Group>
                        <Form.Label>{kedo.t('Date format')}</Form.Label>
                        <Form.Control
                            value={this.state.date_format}
                            as="select"
                            name={'date_format'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'year_month'}>
                                {kedo.t('Year month')}
                            </option>
                            <option value={'quarter'}>
                                {kedo.t('Quarters')}
                            </option>
                            <option value={'year'}>{kedo.t('Year')}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Chart format')}</Form.Label>
                        <Form.Control
                            value={this.state.chart_format}
                            as="select"
                            name={'chart_format'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'line'}>{kedo.t('Line')}</option>
                            <option value={'bar'}>{kedo.t('Bar')}</option>
                            <option value={'pie'}>{kedo.t('Pie')}</option>
                            <option value={'table'}>{kedo.t('Table')}</option>
                        </Form.Control>
                    </Form.Group>
                    {this.state.chart_format === 'bar' ? (
                        <Form.Group>
                            <Form.Label>{kedo.t('Group format')}</Form.Label>
                            <Form.Control
                                value={this.state.group_format}
                                as="select"
                                name={'group_format'}
                                onChange={this.handleNormalChange}
                            >
                                <option value={''}>
                                    {kedo.t('Choose an option')}
                                </option>
                                <option value={'grouped'}>
                                    {kedo.t('Grouped')}
                                </option>
                                <option value={'stacked'}>
                                    {kedo.t('Stacked')}
                                </option>
                            </Form.Control>
                        </Form.Group>
                    ) : null}
                    {this.state.chart_format === 'bar' ? (
                        <Form.Group>
                            <Form.Label>{kedo.t('Chart layout')}</Form.Label>
                            <Form.Control
                                value={this.state.chart_layout}
                                as="select"
                                name={'chart_layout'}
                                onChange={this.handleNormalChange}
                            >
                                <option value={''}>
                                    {kedo.t('Choose an option')}
                                </option>
                                <option value={'vertical'}>
                                    {kedo.t('Vertical')}
                                </option>
                                <option value={'horizontal'}>
                                    {kedo.t('Horizontal')}
                                </option>
                            </Form.Control>
                        </Form.Group>
                    ) : null}
                    {this.state.chart_format === 'table' ? (
                        <Form.Group>
                            <Form.Label>{kedo.t('Table layout')}</Form.Label>
                            <Form.Control
                                value={this.state.table_layout}
                                as="select"
                                name={'table_layout'}
                                onChange={this.handleNormalChange}
                            >
                                <option value={''}>
                                    {kedo.t('Choose an option')}
                                </option>
                                <option value={'ver1'}>
                                    {kedo.t('Version 1 (Martinique header)')}
                                </option>
                                <option value={'ver2'}>
                                    {kedo.t(
                                        'Version 2 (Dark header/green striped'
                                    )}
                                </option>
                                <option value={'ver3'}>
                                    {kedo.t('Version 3 (Havelock Blue)')}
                                </option>
                                <option value={'ver4'}>
                                    {kedo.t('Version 4 (Red)')}
                                </option>
                                <option value={'ver5'}>
                                    {kedo.t(
                                        'Version 5 (Dark header/light blue striped)'
                                    )}
                                </option>
                                <option value={'ver6'}>
                                    {kedo.t('Version 6 (Purple)')}
                                </option>
                            </Form.Control>
                        </Form.Group>
                    ) : null}
                    {this.state.chart_format === 'table' ? (
                        <Form.Group>
                            <Form.Label>{kedo.t('Reverse axis')}</Form.Label>
                            <Form.Control
                                value={this.state.reverse_axis}
                                as="select"
                                name={'reverse_axis'}
                                onChange={this.handleNormalChange}
                            >
                                <option value={''}>
                                    {kedo.t('Choose an option')}
                                </option>
                                <option value={'no'}>{kedo.t('no')}</option>
                                <option value={'yes'}>{kedo.t('yes')}</option>
                            </Form.Control>
                        </Form.Group>
                    ) : null}
                    <Form.Group>
                        <Form.Label>{kedo.t('Show Y scrollbars')}</Form.Label>
                        <Form.Control
                            value={this.state.show_y_scroll}
                            as="select"
                            name={'show_y_scroll'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'no'}>{kedo.t('no')}</option>
                            <option value={'yes'}>{kedo.t('yes')}</option>
                        </Form.Control>
                    </Form.Group>
                </div>
            )
        } else if (blockType === 'count_list_options') {
            if (!this.state.item.defDossier) {
                return ''
            }
            return (
                <div>
                    <Form.Group>
                        <Form.Label className={'required'}>
                            {kedo.t('List field')}
                        </Form.Label>
                        <SelectList
                            currentValue={this.state.count_list_field}
                            onClear={() =>
                                this.setState({ count_list_field: null })
                            }
                            searchName={'search'}
                            kedo={this.props.kedo}
                            urlParams={{
                                page: 0,
                                limit: 200,
                                type: 'list',
                                environment: kedo.env().getEnvironment().id,
                                defDossier: this.state.item.defDossier,
                            }}
                            onDisplayName={(item) => item.name}
                            url={kedo.api().getDefDossierDefFieldEndpoint()}
                            onSelect={(item) => {
                                this.setState({ count_list_field: item.id })
                            }}
                        />
                    </Form.Group>
                    <NivoSchemeSelector
                        kedo={kedo}
                        value={this.state.scheme}
                        onChange={(e) =>
                            this.setState({ scheme: e.target.value })
                        }
                    />
                    <Form.Group>
                        <Form.Label>
                            {kedo.t('Date')} <small>{kedo.t('Optional')}</small>
                        </Form.Label>
                        <SelectList
                            currentValue={this.state.date_field}
                            onClear={() => this.setState({ date_field: null })}
                            searchName={'search'}
                            kedo={this.props.kedo}
                            urlParams={{
                                type: 'date',
                                environment: kedo.env().getEnvironment().id,
                                defDossier: this.state.item.defDossier,
                            }}
                            onDisplayName={(item) => item.name}
                            url={kedo.api().getDefDossierDefFieldEndpoint()}
                            onSelect={(item) => {
                                // let settings = this.state.item.settings;
                                // settings.date_field = item.id;
                                this.setState({ date_field: item.id })
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {kedo.t('Use dossier creation date')}{' '}
                            <small>{kedo.t('Optional')}</small>
                        </Form.Label>
                        <Form.Check
                            value={true}
                            checked={this.state.use_creation_date === true}
                            onChange={(e) => {
                                this.setState({
                                    use_creation_date: e.target.checked,
                                })
                            }}
                            type={'checkbox'}
                            id={`use_creation_date`}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Date format')}</Form.Label>
                        <Form.Control
                            value={this.state.date_format}
                            as="select"
                            name={'date_format'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'year_month'}>
                                {kedo.t('Year month')}
                            </option>
                            <option value={'quarter'}>
                                {kedo.t('Quarters')}
                            </option>
                            <option value={'year'}>{kedo.t('Year')}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Chart format')}</Form.Label>
                        <Form.Control
                            value={this.state.chart_format}
                            as="select"
                            name={'chart_format'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'line'}>{kedo.t('Line')}</option>
                            <option value={'bar'}>{kedo.t('Bar')}</option>
                            <option value={'pie'}>{kedo.t('Pie')}</option>
                            <option value={'table'}>{kedo.t('Table')}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Group format')}</Form.Label>
                        <Form.Control
                            value={this.state.group_format}
                            as="select"
                            name={'group_format'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'grouped'}>
                                {kedo.t('Grouped')}
                            </option>
                            <option value={'stacked'}>
                                {kedo.t('Stacked')}
                            </option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Chart layout')}</Form.Label>
                        <Form.Control
                            value={this.state.chart_layout}
                            as="select"
                            name={'chart_layout'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'vertical'}>
                                {kedo.t('Vertical')}
                            </option>
                            <option value={'horizontal'}>
                                {kedo.t('Horizontal')}
                            </option>
                        </Form.Control>
                    </Form.Group>
                    {this.state.chart_format === 'table' ? (
                        <Form.Group>
                            <Form.Label>{kedo.t('Reverse axis')}</Form.Label>
                            <Form.Control
                                value={this.state.reverse_axis}
                                as="select"
                                name={'reverse_axis'}
                                onChange={this.handleNormalChange}
                            >
                                <option value={''}>
                                    {kedo.t('Choose an option')}
                                </option>
                                <option value={'no'}>{kedo.t('no')}</option>
                                <option value={'yes'}>{kedo.t('yes')}</option>
                            </Form.Control>
                        </Form.Group>
                    ) : null}
                    <Form.Group>
                        <Form.Label>{kedo.t('Enable Label')}</Form.Label>
                        <Form.Control
                            value={this.state.enable_labels}
                            as="select"
                            name={'enable_labels'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'no'}>{kedo.t('no')}</option>
                            <option value={'yes'}>{kedo.t('yes')}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Enable Grid')}</Form.Label>
                        <Form.Control
                            value={this.state.enable_grid}
                            as="select"
                            name={'enable_grid'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'no'}>{kedo.t('no')}</option>
                            <option value={'yes'}>{kedo.t('yes')}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Show Y scrollbars')}</Form.Label>
                        <Form.Control
                            value={this.state.show_y_scroll}
                            as="select"
                            name={'show_y_scroll'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'no'}>{kedo.t('no')}</option>
                            <option value={'yes'}>{kedo.t('yes')}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Reverse axis')}</Form.Label>
                        <Form.Control
                            value={this.state.reverse_axis}
                            as="select"
                            name={'reverse_axis'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'no'}>{kedo.t('no')}</option>
                            <option value={'yes'}>{kedo.t('yes')}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Legend direction')}</Form.Label>
                        <Form.Control
                            value={this.state.legend_direction}
                            as="select"
                            name={'legend_direction'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'left-to-right'}>
                                {kedo.t('left-to-right')}
                            </option>
                            <option value={'right-to-left'}>
                                {kedo.t('right-to-left')}
                            </option>
                            <option value={'top-to-bottom'}>
                                {kedo.t('top-to-bottom')}
                            </option>
                            <option value={'bottom-to-top'}>
                                {kedo.t('bottom-to-top')}
                            </option>
                        </Form.Control>
                    </Form.Group>
                </div>
            )
        } else if (blockType === 'dashboard') {
            return (
                <>
                    {this.state.showAddView ? (
                        <DisplayCustomViewForm
                            onSuccess={() => {
                                this.setState({ showAddView: false })
                                setTimeout(() => this.fetchCustomViews(), 50)
                            }}
                            onClose={() =>
                                this.setState({ showAddView: false })
                            }
                            kedo={this.props.kedo}
                            defDossierId={this.state.item.defDossier}
                        />
                    ) : null}
                    <Form.Group>
                        <Form.Label className={'required'}>
                            {kedo.t('View')}
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                value={this.state.view_name}
                                as="select"
                                name={'view_name'}
                                onChange={this.handleNormalChange}
                            >
                                <option>{kedo.t('Choose an option')}</option>
                                <option value={'list'}>{kedo.t('List')}</option>
                                {this.state.views.map((displayView) => (
                                    <option
                                        key={displayView.id}
                                        value={displayView.view_name}
                                    >
                                        {displayView.view_name}
                                    </option>
                                ))}
                            </Form.Control>
                            <Button
                                onClick={() =>
                                    this.setState({ showAddView: true })
                                }
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('Limit')}</Form.Label>
                        <Form.Control
                            value={this.state.limit}
                            name={'limit'}
                            type={'number'}
                            onChange={this.handleNormalChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            value={true}
                            label={kedo.t('Show add buttons')}
                            checked={this.state.showAdd}
                            onChange={(e) =>
                                this.setState({ showAdd: e.target.checked })
                            }
                            type={'checkbox'}
                            id={`show-add`}
                        />
                    </Form.Group>
                    <BlockTabQueries
                        kedo={kedo}
                        tabQueries={this.state.tabQueries}
                        dossierQueries={this.state.dossierQueries}
                        addTabQuery={() => {
                            let tabQueries = this.state.tabQueries
                            if (!Array.isArray(tabQueries)) {
                                tabQueries = []
                            }
                            tabQueries.push({ query: null, title: '' })
                            this.setState({ tabQueries: tabQueries })
                        }}
                        onChangeTabQueries={(item, i, name, value) => {
                            let tabQueries = this.state.tabQueries
                            tabQueries[i] = item
                            tabQueries[i][name] = value

                            this.setState({ tabQueries: tabQueries })
                        }}
                        deleteTabQuery={(i) => {
                            let tabQueries = this.state.tabQueries
                            tabQueries.splice(i, 1)
                            this.setState({ tabQueries: tabQueries })
                        }}
                    />
                    <BlockFormTemplateSelect
                        handleTemplateOptionsChange={
                            this.handleTemplateOptionsChange
                        }
                        layout={this.state.layout}
                        view_name={this.state.view_name}
                        template={this.state.template}
                        templateOptions={this.state.templateOptions}
                        defDossier={this.state.item.defDossier}
                        handleNormalChange={this.handleNormalChange}
                        handleChangeVar={this.handleChangeVar}
                        kedo={this.props.kedo}
                    />
                </>
            )
        } else if (blockType === 'sum_fields') {
            return (
                <>
                    <Form.Group>
                        <Form.Label>{kedo.t('Sum field')}</Form.Label>
                        <SelectList
                            currentValue={this.state.sum_field}
                            onClear={() => this.setState({ sum_field: null })}
                            searchName={'search'}
                            kedo={this.props.kedo}
                            urlParams={{
                                type: 'amount',
                                environment: kedo.env().getEnvironment().id,
                                defDossier: this.state.item.defDossier,
                            }}
                            onDisplayName={(item) => item.name}
                            url={kedo.api().getDefDossierDefFieldEndpoint()}
                            onSelect={(item) => {
                                let settings = this.state.item.settings
                                settings.sum_field = item.id
                                this.setState({
                                    sum_field: item.id,
                                    settings: settings,
                                })
                            }}
                        />
                    </Form.Group>
                </>
            )
        } else if (blockType === 'online_users') {
            return (
                <div>
                    <NivoSchemeSelector
                        kedo={kedo}
                        value={this.state.scheme}
                        onChange={(e) =>
                            this.setState({ scheme: e.target.value })
                        }
                    />
                </div>
            )
        } else if (blockType === 'active_users') {
            return (
                <div>
                    <NivoSchemeSelector
                        kedo={kedo}
                        value={this.state.scheme}
                        onChange={(e) =>
                            this.setState({ scheme: e.target.value })
                        }
                    />
                </div>
            )
        } else if (blockType === 'block_container') {
            return (
                <div>
                    <Form.Group>
                        <Form.Label>{kedo.t('Layout')}</Form.Label>
                        <Form.Control
                            value={this.state.layout}
                            as="select"
                            onChange={this.handleNormalChange}
                            name={'layout'}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'horizontal'}>
                                {kedo.t('horizontal')}
                            </option>
                            <option value={'vertical'}>
                                {kedo.t('vertical')}
                            </option>
                            <option value={'carousel'}>
                                {kedo.t('carousel')}
                            </option>
                            <option value={'accordion'}>
                                {kedo.t('accordion')}
                            </option>
                        </Form.Control>
                        {this.state.errors !== null &&
                        this.state.errors.period ? (
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.period}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>
                </div>
            )
        } else if (blockType === 'new_dossier_statistics') {
            return (
                <div>
                    <NivoSchemeSelector
                        kedo={kedo}
                        value={this.state.scheme}
                        onChange={(e) =>
                            this.setState({ scheme: e.target.value })
                        }
                    />
                </div>
            )
        } else if (blockType === 'date_statistics') {
            return (
                <div>
                    <NivoSchemeSelector
                        kedo={kedo}
                        value={this.state.scheme}
                        onChange={(e) =>
                            this.setState({ scheme: e.target.value })
                        }
                    />
                    <Form.Group>
                        <Form.Label className={'required'}>
                            {kedo.t('Period')}
                        </Form.Label>
                        <Form.Control
                            isInvalid={
                                this.state.errors !== null &&
                                this.state.errors.period
                            }
                            value={this.state.period}
                            as="select"
                            name={'period'}
                            onChange={this.handleNormalChange}
                        >
                            <option value={''}>
                                {kedo.t('Choose an option')}
                            </option>
                            <option value={'week'}>{kedo.t('Week')}</option>
                            <option value={'month'}>{kedo.t('Month')}</option>
                            <option value={'year'}>{kedo.t('Year')}</option>
                        </Form.Control>
                        {this.state.errors !== null &&
                        this.state.errors.period ? (
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.period}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{kedo.t('List field')}</Form.Label>
                        <SelectList
                            currentValue={this.state.list_field}
                            onClear={() => this.setState({ list_field: null })}
                            searchName={'search'}
                            kedo={this.props.kedo}
                            urlParams={{
                                type: 'list',
                                environment: kedo.env().getEnvironment().id,
                                defDossier: this.state.item.defDossier,
                            }}
                            onDisplayName={(item) => item.name}
                            url={kedo.api().getDefDossierDefFieldEndpoint()}
                            onSelect={(item) => {
                                let settings = this.state.item.settings
                                settings.list_field = item.id
                                this.setState({
                                    list_field: item.id,
                                    settings: settings,
                                })
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {kedo.t('Date')} <small>{kedo.t('Optional')}</small>
                        </Form.Label>
                        <SelectList
                            currentValue={this.state.date_field}
                            onClear={() => this.setState({ date_field: null })}
                            searchName={'search'}
                            kedo={this.props.kedo}
                            urlParams={{
                                type: 'date',
                                environment: kedo.env().getEnvironment().id,
                                defDossier: this.state.item.defDossier,
                            }}
                            onDisplayName={(item) => item.name}
                            url={kedo.api().getDefDossierDefFieldEndpoint()}
                            onSelect={(item) => {
                                // let settings = this.state.item.settings;
                                // settings.date_field = item.id;
                                this.setState({ date_field: item.id })
                            }}
                        />
                    </Form.Group>
                </div>
            )
        }

        return ''
    }

    render() {
        const kedo = this.props.kedo
        const isEntityRequired = [
            'dashboard',
            'sum_fields',
            'count_list_options',
            'date_statistics',
            'count_link_options',
            'list_option_percentage_conditions',
        ].includes(this.state.item.type)
            ? true
            : false
        return (
            <Modal size={'xl'} show={true} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {kedo.t(this.state.item.id ? 'Edit' : 'Add')}{' '}
                        {kedo.t('Block').toLowerCase()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container defaultActiveKey="general">
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="general">
                                            {kedo.t('Generic')}
                                            {this.state.errors != null &&
                                            this.state.errors &&
                                            (this.state.errors.name ||
                                                this.state.errors.type) ? (
                                                <FontAwesomeIcon
                                                    className={`validationIcon validationFailed`}
                                                    icon={faExclamationCircle}
                                                />
                                            ) : null}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="layout">
                                            {kedo.t('Layout')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="permissions">
                                            {kedo.t('Permissions')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="sort">
                                            {kedo.t('Sort')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    {this.state.item.id ? (
                                        <Nav.Item>
                                            <Nav.Link eventKey="misc">
                                                {kedo.t('Block type specific')}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ) : null}
                                    {this.state.item.id ? (
                                        <Nav.Item>
                                            <Nav.Link eventKey="info">
                                                {kedo.t('Info')}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ) : null}
                                </Nav>
                            </Col>
                            <Col sm={9} className={'pillsContent'}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="general">
                                        <h3>{kedo.t('Generic')}</h3>
                                        {this.renderTranslations(
                                            this.state.item.translations
                                        )}
                                        <br />
                                        <Form.Group>
                                            <Form.Label className={'required'}>
                                                {kedo.t('Type')}
                                            </Form.Label>
                                            <Form.Control
                                                isInvalid={
                                                    this.state.errors !==
                                                        null &&
                                                    this.state.errors.type
                                                }
                                                value={this.state.item.type}
                                                as="select"
                                                name={'type'}
                                                onChange={this.handleChange}
                                            >
                                                <option value={''}>
                                                    {kedo.t('Choose an option')}
                                                </option>
                                                <option value={'dashboard'}>
                                                    {kedo.t('Dashboard')}
                                                </option>
                                                <option
                                                    value={
                                                        'list_option_percentage_conditions'
                                                    }
                                                >
                                                    {kedo.t(
                                                        'List options percentage matching condition'
                                                    )}
                                                </option>
                                                <option
                                                    value={'count_list_options'}
                                                >
                                                    {kedo.t(
                                                        'Count list options'
                                                    )}
                                                </option>
                                                <option
                                                    value={'count_link_options'}
                                                >
                                                    {kedo.t(
                                                        'Count link options'
                                                    )}
                                                </option>
                                                <option value={'sum_fields'}>
                                                    {kedo.t('Sum amount field')}
                                                </option>
                                                <option value={'online_users'}>
                                                    {kedo.t('Online users')}
                                                </option>
                                                <option value={'active_users'}>
                                                    {kedo.t('Active users')}
                                                </option>
                                                <option
                                                    value={
                                                        'new_dossier_statistics'
                                                    }
                                                >
                                                    {kedo.t(
                                                        'New dossier statistics'
                                                    )}
                                                </option>
                                                <option
                                                    value={'date_statistics'}
                                                >
                                                    {kedo.t('Date statistics')}
                                                </option>
                                                <option
                                                    value={'block_container'}
                                                >
                                                    {kedo.t('Block Container')}
                                                </option>
                                                <option value={'geomap'}>
                                                    {kedo.t('Geomap')}
                                                </option>
                                                <option value={'funnel'}>
                                                    {kedo.t('Funnel')}
                                                </option>
                                            </Form.Control>
                                            {this.state.errors !== null &&
                                            this.state.errors.type ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {this.state.errors.type}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        {isEntityRequired ? (
                                            <Form.Group>
                                                <Form.Label
                                                    className={'required'}
                                                >
                                                    {kedo.t('Entity')}
                                                </Form.Label>
                                                <Form.Control
                                                    isInvalid={
                                                        this.state.errors !==
                                                            null &&
                                                        this.state.errors
                                                            .defDossier
                                                    }
                                                    value={
                                                        this.state.item
                                                            .defDossier
                                                    }
                                                    as="select"
                                                    name={'defDossier'}
                                                    onChange={(e) => {
                                                        this.handleChange(e)
                                                        this.fetchQueries()
                                                        this.fetchCustomViews()
                                                    }}
                                                >
                                                    <option>
                                                        {kedo.t(
                                                            'Choose an option'
                                                        )}
                                                    </option>
                                                    {this.state.defDossiers.map(
                                                        (item) => (
                                                            <option
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {this.props.kedo
                                                                    .env()
                                                                    .translateItem(
                                                                        item,
                                                                        'defdossier'
                                                                    )}
                                                            </option>
                                                        )
                                                    )}
                                                </Form.Control>
                                                {this.state.errors !== null &&
                                                this.state.errors.defDossier ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            this.state.errors
                                                                .defDossier
                                                        }
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        ) : null}
                                        <Form.Group>
                                            <Form.Check
                                                readOnly={this.state.loading}
                                                name={'dashboard'}
                                                checked={
                                                    this.state.item.dashboard &&
                                                    this.state.item.dashboard
                                                }
                                                onChange={(e) => {
                                                    let item = this.state.item
                                                    item[e.target.name] =
                                                        !this.state.item
                                                            .dashboard
                                                    this.setState({
                                                        item: item,
                                                    })
                                                }}
                                                type="checkbox"
                                                label={kedo.t(
                                                    'Show on dashboard'
                                                )}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>
                                                {kedo.t('Parent')}
                                            </Form.Label>
                                            <SelectList
                                                currentValue={this.state.parent}
                                                onClear={() =>
                                                    this.setState({
                                                        parent: null,
                                                    })
                                                }
                                                searchName={'search'}
                                                kedo={this.props.kedo}
                                                urlParams={{
                                                    page: 0,
                                                    limit: 200,
                                                    type: 'block_container',
                                                    environment: kedo
                                                        .env()
                                                        .getEnvironment().id,
                                                }}
                                                onDisplayName={(item) =>
                                                    kedo
                                                        .env()
                                                        .translateItem(
                                                            item,
                                                            'mypageblock'
                                                        )
                                                }
                                                url={kedo
                                                    .api()
                                                    .getMyPageBlockEndpoint()}
                                                onSelect={(item) => {
                                                    this.setState({
                                                        parent: item.id,
                                                    })
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <DossierQuerySelect
                                                kedo={kedo}
                                                onChange={(newId) => {
                                                    let item = this.state.item
                                                    item.dossierQuery = newId
                                                    this.setState({
                                                        item: item,
                                                    })
                                                }}
                                                value={
                                                    this.state.item
                                                        ?.dossierQuery
                                                }
                                                ddId={
                                                    this.state.item.defDossier
                                                }
                                            />
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="layout">
                                        <h3>{kedo.t('Layout')}</h3>
                                        <Form.Group>
                                            <Form.Label>
                                                {kedo.t('Column width')}
                                            </Form.Label>
                                            <Form.Control
                                                isInvalid={
                                                    this.state.errors !==
                                                        null &&
                                                    this.state.errors.col
                                                }
                                                value={
                                                    this.state.item.col
                                                        ? this.state.item.col
                                                        : '12'
                                                }
                                                type="number"
                                                min={1}
                                                max={12}
                                                name={'col'}
                                                onChange={this.handleChange}
                                            />
                                            <Form.Text className="text-muted">
                                                {this.props.kedo.t(
                                                    'colspan_message'
                                                )}
                                            </Form.Text>
                                            {this.state.errors !== null &&
                                            this.state.errors.col ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {this.state.errors.col}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>
                                                {kedo.t('Rank')}
                                            </Form.Label>
                                            <Form.Control
                                                isInvalid={
                                                    this.state.errors !==
                                                        null &&
                                                    this.state.errors.rank
                                                }
                                                value={
                                                    this.state.item.rank
                                                        ? this.state.item.rank
                                                        : '0'
                                                }
                                                type="number"
                                                name={'rank'}
                                                onChange={this.handleChange}
                                            />
                                            {this.state.errors !== null &&
                                            this.state.errors.rank ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {this.state.errors.rank}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="permissions">
                                        <h3>{kedo.t('Permissions')}</h3>
                                        <Form.Group>
                                            <Form.Label>
                                                {kedo.t('Roles')}
                                            </Form.Label>
                                            {this.state.availableRoles.map(
                                                (item) => (
                                                    <Form.Check
                                                        value={item.id}
                                                        key={item.id}
                                                        checked={
                                                            this.state.item &&
                                                            this.state.item
                                                                .roles &&
                                                            this.state.item.roles.includes(
                                                                item.id
                                                            )
                                                        }
                                                        onChange={
                                                            this.onChangeRole
                                                        }
                                                        type={'checkbox'}
                                                        label={item.name}
                                                        id={`role-${item.id}`}
                                                    />
                                                )
                                            )}
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="sort">
                                        {this.state.item &&
                                        this.state.item.defDossier ? (
                                            <BlockSortSettings
                                                sort={this.state.sort}
                                                viewName={this.state.view_name}
                                                defDossierId={
                                                    this.state.item.defDossier
                                                }
                                                kedo={this.props.kedo}
                                                onChangeSort={(
                                                    sortSettings
                                                ) => {
                                                    this.setState({
                                                        sort: sortSettings,
                                                    })
                                                }}
                                            />
                                        ) : null}
                                    </Tab.Pane>
                                    {this.state.item.id ? (
                                        <Tab.Pane eventKey="misc">
                                            {this.renderSettingsByType(
                                                this.state.item.type
                                            )}
                                        </Tab.Pane>
                                    ) : null}
                                    {this.state.item.id ? (
                                        <Tab.Pane eventKey={'info'}>
                                            <ObjectCreatedInfo
                                                item={this.state.item}
                                                kedo={kedo}
                                            />
                                        </Tab.Pane>
                                    ) : null}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    {this.state.delete ? (
                        <ConfirmDeleteDialog
                            errorLabel={kedo.t('Something went wrong')}
                            kedo={kedo}
                            url={
                                kedo.api().getMyPageBlockEndpoint() +
                                '/' +
                                this.state.deleteItem
                            }
                            onClose={() => {
                                this.setState({ delete: false })
                                this.fetchItems()
                            }}
                            onSuccess={() => (window.location = '/')}
                        />
                    ) : null}
                    {this.state.item.id ? (
                        <Button
                            className={'mr-auto'}
                            onClick={() =>
                                this.setState({
                                    delete: true,
                                    deleteItem: this.props.item.id,
                                })
                            }
                            variant="danger"
                            title={this.props.kedo.t('Delete')}
                            onSucces={() => (window.location = '/')}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                            &nbsp; {this.props.kedo.t('Delete')}
                        </Button>
                    ) : null}
                    <Button onClick={this.submit} variant="primary">
                        {this.state.submitting ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {kedo.t('Save')}
                    </Button>
                    <Button
                        onClick={() => this.props.onClose()}
                        variant="secondary"
                    >
                        {kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default BlockForm
