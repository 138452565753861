import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-duotone-svg-icons/faStar'
import { toast } from 'react-toastify'

class MultiContentDossierFavorite extends Component {
    constructor(props) {
        super(props)

        this.state = {
            favoritesDefDossierForm:
                this.props.favorite === true ? true : false,
            done: 0,
            total: this.props.items.length,
        }

        this.addFavorites = this.addFavorites.bind(this)

        this.toastOptions = {
            position: 'bottom-center',
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }
    }

    addFavorites(favorites) {
        let total = this.props.items.length
        let items = this.props.items
        let itemsDone = 0
        const kedo = this.props.kedo
        this.setState({
            favorites: favorites,
        })

        items.map((dossierId) => {
            let url = kedo
                .api()
                .getContentDossierFavoriteEndpoint(dossierId, favorites)

            kedo.api()
                .post(url)
                .then((response) => {
                    itemsDone = itemsDone + 1
                    if (itemsDone === total) {
                        this.handleExit()
                    } else {
                        this.setState({
                            done: itemsDone,
                        })
                    }
                })
        })
    }

    deleteFavorites(favorites) {
        let total = this.props.items.length
        let items = this.props.items
        let itemsDone = 0
        const kedo = this.props.kedo
        this.setState({
            favorites: favorites,
        })
        items.map((dossierId) => {
            let url = kedo
                .api()
                .getContentDossierFavoriteEndpoint(dossierId, favorites)

            kedo.api()
                .delete(url)
                .then((response) => {
                    itemsDone = itemsDone + 1
                    if (itemsDone === total) {
                        this.handleExit()
                    } else {
                        this.setState({
                            done: itemsDone,
                        })
                    }
                })
        })
    }

    handleExit() {
        this.props.onClose()
    }

    render() {
        return (
            <span>
                <Button
                    variant={'outline-secondary'}
                    onClick={() => {
                        this.addFavorites(),
                            toast.success(
                                this.props.kedo.t(
                                    'dossier_errors.favorite_add'
                                ),
                                this.toastOptions
                            )
                    }}
                >
                    <FontAwesomeIcon icon={faStar} />
                    &nbsp; {this.props.kedo.t('Favorite')}
                </Button>
                <Button
                    variant={'outline-secondary'}
                    onClick={() => {
                        this.deleteFavorites()
                        toast.success(
                            this.props.kedo.t('dossier_errors.favorite_remove'),
                            this.toastOptions
                        )
                    }}
                >
                    <FontAwesomeIcon icon={faStar} />
                    &nbsp; {this.props.kedo.t('Remove favorite')}
                </Button>
            </span>
        )
    }
}

export default MultiContentDossierFavorite
