import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/pro-duotone-svg-icons/faFolder'

import LoadingDefault from '../../../Elements/Loading/LoadingDefault'

class MultiContentDossierArchive extends Component {
    constructor(props) {
        super(props)

        this.state = {
            archiveDefDossierForm: false,
            archiving: false,
            dearchiving: false,
            dossier: {},
        }

        this.archive = this.archive.bind(this)
        this.dearchive = this.dearchive.bind(this)
    }

    archive() {
        if (!this.props.checked) {
            return
        }

        let selectedItems = this.props.checked
        let x

        let allSelectedItems = selectedItems.length
        let counter = 0

        for (x of selectedItems) {
            let url =
                this.props.kedo.api().getContentDossierEndpoint() +
                '/' +
                x +
                '/archive'
            this.setState({ archiving: true })
            this.props.kedo
                .api()
                .get(url)
                .then((response) => {
                    let dossier = this.state.dossier
                    dossier.archived = true
                    this.setState({ dossier: dossier })
                    counter = counter + 1

                    if (counter === allSelectedItems) {
                        this.setState({ archiving: false })
                        this.handleExit()
                    }
                })
        }
    }

    dearchive() {
        if (!this.props.checked) {
            return
        }

        let selectedItems = this.props.checked
        let x

        let allSelectedItems = selectedItems.length
        let counter = 0

        for (x of selectedItems) {
            let url =
                this.props.kedo.api().getContentDossierEndpoint() +
                '/' +
                x +
                '/dearchive'

            this.setState({ dearchiving: true })

            this.props.kedo
                .api()
                .get(url)
                .then((response) => {
                    let dossier = this.state.dossier
                    dossier.archived = false
                    this.setState({ dossier: dossier })
                    counter = counter + 1

                    if (counter === allSelectedItems) {
                        this.setState({ dearchiving: false })
                        this.handleExit()
                    }
                })
        }
    }

    handleExit() {
        this.props.onClose()
    }

    render() {
        if (!this.props.archived) {
            return (
                <Button variant={'outline-secondary'} onClick={this.archive}>
                    {this.state.archiving ? (
                        <LoadingDefault />
                    ) : (
                        <FontAwesomeIcon icon={faFolder} />
                    )}
                    &nbsp; {this.props.kedo.t('Archive')}
                </Button>
            )
        } else {
            return (
                <Button variant={'outline-secondary'} onClick={this.dearchive}>
                    {this.state.dearchiving ? (
                        <LoadingDefault />
                    ) : (
                        <FontAwesomeIcon icon={faFolder} />
                    )}
                    &nbsp; {this.props.kedo.t('Dearchive')}
                </Button>
            )
        }
    }
}

export default MultiContentDossierArchive
