import React from 'react'
import { Form, Button } from 'react-bootstrap'
import Api from '../../Api'
import LoadingDefault from '../Elements/Loading/LoadingDefault'

export default class TwoFactorAuthentication extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            otpKey: '',
            password: this.props.password,
            username: this.props.username,
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    onSubmit(event) {
        event.preventDefault()
        if (!this.state.otpKey || this.state.otpKey.length <= 0) {
            return
        }

        let api = new Api()
        this.setState({
            loading: true,
            loginErrors: false,
        })
        api.post(
            '/login_check',
            { username: this.props.username, password: this.props.password },
            { headers: { 'X-OTP-Token': this.state.otpKey } }
        )
            .then((response) => {
                if (response.data) {
                    this.props.onSuccess(response.data)
                }
                this.setState({ loading: false })
            })
            .catch((err) => {
                console.log('Errrorrrr', err)
                this.setState({
                    loading: false,
                    loginErrors: true,
                })
            })
    }

    reload() {
        window.location.reload()
    }

    onHandlePincodeChange = (e) => {
        let pincode = e.target.value

        if (
            e.keyCode !== 48 &&
            e.keyCode !== 8 &&
            !Number(pincode) &&
            pincode !== '' &&
            pincode !== '0' &&
            pincode !== '00' &&
            pincode !== '000' &&
            pincode !== '0000' &&
            pincode !== '00000' &&
            pincode !== '000000'
        ) {
            return
        }
        this.setState({
            [e.target.name]: pincode,
        })
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Form onSubmit={this.onSubmit}>
                <h1>{kedo.t('Verification code')}</h1>
                <p>
                    {kedo.t('Enter your verification code below')}.
                    <br />
                    {kedo.t('Use your authentication device')}
                </p>
                {this.state.loginErrors ? (
                    <div className="alert-danger alert">
                        {kedo.t('Incorrect verification code')}.
                    </div>
                ) : null}
                <div className="form-group">
                    <input
                        type="text"
                        name="otpKey"
                        maxLength={6}
                        className="form-control"
                        autoFocus={true}
                        autoComplete="off"
                        value={this.state.otpKey}
                        placeholder={kedo.t('Verification code')}
                        onChange={this.onHandlePincodeChange}
                        required
                    />
                </div>
                <Button
                    block
                    onClick={this.onSubmit}
                    disabled={!this.state.otpKey || this.state.loading}
                    title={
                        !this.state.otpKey
                            ? 'Enter a verification code to continue'
                            : null
                    }
                >
                    {kedo.t('Verify')}{' '}
                    {this.state.loading ? <LoadingDefault /> : null}
                </Button>
                <Button variant={'tertiary'} size="sm" onClick={this.reload}>
                    {kedo.t('Back to')} {kedo.t('Login').toLowerCase()}
                </Button>
            </Form>
        )
    }
}
