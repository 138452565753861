import React, { Component } from 'react'
import { Alert, Button, Col, Form, Modal, Nav, Row, Tab } from 'react-bootstrap'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import DefDossierDefFieldSettings from './DefDossierDefFieldSettings/DefDossierDefFieldSettings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'
import AddDisplayByDataFieldModal from './AddDisplayByDataFieldModal'
import ObjectCreatedInfo from '../Elements/ObjectCreatedInfo'

class DefDossierDefFieldForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeDefField: false,
            defDossier: this.props.defDossier,
            submitting: false,
            showDisplayQuestion: false,
            isExisting: this.props.item && this.props.item.id > 0,
            errors: false,
            dataFields: [],
            baseFields: [],
            infoItem: null,
            locked: this.props.item?.locked,
            item: this.props.item
                ? {
                      name: this.props.item.name,
                      defField: this.props.item.def_field.id,
                      def_field: this.props.item.def_field,
                      obligatory: this.props.item.obligatory,
                      locked: this.props.item.locked,
                      settings:
                          this.props.item &&
                          this.props.item.settings &&
                          !Array.isArray(this.props.item.settings)
                              ? this.props.item.settings
                              : {},
                  }
                : {
                      settings: {},
                  },
        }

        this.handleErrors = this.handleErrors.bind(this)
        this.handleSuccess = this.handleSuccess.bind(this)
        this.onSettingsChange = this.onSettingsChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
        this.submitDataField = this.submitDataField.bind(this)
    }

    /**
     * @param response
     * @returns {boolean}
     */
    hasFormErrors(response) {
        if (
            response.response.status === 400 &&
            response.response.data &&
            response.response.data.errors
        ) {
            return true
        }

        return false
    }

    handleSuccess(showDisplayQuestion, item, originalName) {
        if (showDisplayQuestion === true) {
            if (originalName) {
                item.name = originalName
            }
            this.setState({
                showDisplayQuestion: true,
                displayItem: item,
            })
        } else {
            this.setState({
                submitting: false,
                item: {},
                errors: false,
            })

            this.props.onClose(true)
        }
    }

    handleErrors(response) {
        if (!this.hasFormErrors(response)) {
            this.setState({
                submitting: false,
                errors: {
                    generic: this.props.kedo.t('Something went wrong'),
                },
            })
        }
        this.setState({
            submitting: false,
            errors: response.response.data.errors,
        })
    }

    handleCheck(event) {
        let item = this.state.item
        item[event.target.name] = event.target.checked
        this.setState({ item: item })
    }

    handleChange(event) {
        let item = this.state.item
        item[event.target.name] = event.target.value
        this.setState({ item: item })
    }

    submitDataField() {
        let errors = {}

        if (!this.state.item.name || !this.state.item.defField) {
            if (
                this.state.item &&
                (!this.state.item.name || this.state.item.name.length <= 0)
            ) {
                errors['name'] = this.props.kedo.t('This is a required field')
            }
            if (
                this.state.item &&
                (!this.state.item.defField ||
                    this.state.item.defField.length <= 0)
            ) {
                errors['defField'] = this.props.kedo.t(
                    'This is a required field'
                )
            }

            this.setState({ errors: errors })
            return
        }

        this.setState({
            submitting: true,
            errors: false,
        })

        let item = {
            obligatory: this.state.item.obligatory,
            defField: this.state.item.defField,
            name: this.state.item.name.replace(/(\s|\W)+/g, '_'),
            defDossier: this.state.defDossier,
            settings: this.state.item.settings,
            locked: this.state.item.locked,
        }
        let originalName = this.state.item.name
        if (this.props.item && this.props.item.id) {
            item.id = this.props.item.id
            item.uuid = this.props.item.uuid
        }

        if (item.id) {
            this.props.kedo
                .api()
                .put(
                    this.props.kedo.api().getDefDossierDefFieldEndpoint() +
                        '/' +
                        item.id,
                    item
                )
                .then((successResponse) => this.handleSuccess())
                .catch((errResponse) => this.handleErrors(errResponse))
        } else {
            item.environment = this.props.kedo.env().getEnvironment().id
            item.defDossier = parseInt(this.state.defDossier)
            item.uuid = this.props.kedo.utils().uuidv4()

            this.props.kedo
                .api()
                .post(
                    this.props.kedo.api().getDefDossierDefFieldEndpoint(),
                    item
                )
                .then((successResponse) => {
                    this.handleSuccess(true, item, originalName)
                })
                .catch((errResponse) => this.handleErrors(errResponse))
        }
    }

    fetchBaseFields() {
        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 500,
            },
        }

        if (
            this.props.item &&
            this.props.item.id > 0 &&
            this.props.item.def_field
        ) {
            params.params.type = this.props.item.def_field.type
        }

        this.setState({ loadingBaseFields: true })
        this.props.kedo
            .api()
            .getCached(this.props.kedo.api().getDefFieldEndpoint(), params)
            .then((response) =>
                this.setState({
                    baseFields: response.data.results,
                    loadingBaseFields: false,
                })
            )
    }

    isTextType(item) {
        if (item.type === 'url' || item.type === 'email') {
            return true
        } else if (item.type === 'text' || item.type === 'textarea') {
            return true
        }
    }

    getDateSettings(settings) {
        if (!settings) {
            return []
        }
        let labels = []
        labels.push({ name: 'restriction', value: settings.restriction })

        return labels
    }

    getTextSettings(settings) {
        if (!settings) {
            settings = {}
        }

        let labels = []
        labels.push({ name: 'regex', value: settings.regex })
        labels.push({ name: 'size', value: settings.size })

        return labels
    }

    onSettingsChange(name, value) {
        let item = this.state.item

        if (typeof item.settings !== 'object' || item.settings === null) {
            item.settings = {}
        }
        item.settings[name] = value

        this.setState({ item: item })
    }

    getDefDossierDefFieldSettings(item) {
        return (
            <DefDossierDefFieldSettings
                onSettingsChange={this.onSettingsChange}
                kedo={this.props.kedo}
                item={item}
                defDossier={this.state.defDossier}
            />
        )
    }

    getCurrentDefFieldSetting(defFieldId) {
        let defField = this.state.baseFields.find(
            (item) => item.id === parseInt(defFieldId)
        )
        if (!defField || !defField.type) {
            return ''
        }

        let fieldType = defField.type
        let settings = defField.settings

        let settingItems = []
        switch (fieldType) {
            case 'date':
                settingItems = this.getDateSettings(settings)
                break
            case 'text':
                settingItems = this.getTextSettings(settings)
                break
            default:
                settingItems = []
        }

        return (
            <div>
                <h5>{this.props.kedo.t('Additional information')}</h5>
                {settingItems && settingItems.length > 0 ? (
                    settingItems.map((item, index) => (
                        <Form.Group key={index}>
                            <Form.Label>{item.name}</Form.Label>
                            <Form.Control
                                disabled
                                name={item.name}
                                value={
                                    item.value
                                        ? item.value
                                        : this.props.kedo.t('Not indicated')
                                }
                            />
                        </Form.Group>
                    ))
                ) : (
                    <Alert variant={'info'}>
                        {this.props.kedo.t('No data available')}
                    </Alert>
                )}
            </div>
        )
    }

    fetchDataField() {
        const kedo = this.props.kedo

        kedo.api()
            .get(kedo.api().getDefDossierDefFieldEndpoint(this.props.item.id))
            .then((response) => {
                this.setState({ infoItem: response.data })
            })
    }

    componentDidMount() {
        this.fetchBaseFields()
        if (this.props?.item?.id) {
            this.fetchDataField()
        }
    }

    render() {
        const kedo = this.props.kedo
        if (this.state.showDisplayQuestion) {
            return (
                <AddDisplayByDataFieldModal
                    kedo={kedo}
                    item={this.state.displayItem}
                    onClose={() => this.handleSuccess()}
                />
            )
        }

        return (
            <Modal size={'xl'} show={true} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.item && this.props.item.name
                            ? this.props.kedo.t('Edit data field') +
                              ': ' +
                              this.props.item.name
                            : this.props.kedo.t('New data field')}
                        {this.props.item ? (
                            <small className={'text-muted'}>
                                {' '}
                                ({this.props.item.id})
                            </small>
                        ) : null}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'pillsModalBody'}>
                    <Tab.Container defaultActiveKey="general">
                        <Row>
                            <Col sm={3} className={'pillsSidebar'}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="general">
                                            {this.props.kedo.t('Default')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="misc">
                                            {this.props.kedo.t('Misc')}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="info">
                                            {this.props.kedo.t('Info')}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9} className={'pillsContent'}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="general">
                                        <h3>
                                            {kedo.t('Default')}{' '}
                                            {kedo.t('Settings').toLowerCase()}
                                        </h3>
                                        <Form.Group>
                                            <Form.Label className={'required'}>
                                                {kedo.t('Name')}
                                            </Form.Label>
                                            <Form.Control
                                                value={this.state.item.name}
                                                name={'name'}
                                                isInvalid={
                                                    this.state.errors !==
                                                        null &&
                                                    this.state.errors.name
                                                }
                                                onChange={this.handleChange}
                                            />
                                            {this.state.errors !== null &&
                                            this.state.errors.name ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {this.state.errors.name}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label className={'required'}>
                                                {kedo.t('Base field')}
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                disable={
                                                    this.state.isExisting &&
                                                    this.props.item.def_field
                                                        .type === 'list'
                                                }
                                                isInvalid={
                                                    this.state.errors !==
                                                        null &&
                                                    this.state.errors.defField
                                                }
                                                name={'defField'}
                                                onChange={this.handleChange}
                                                readOnly={
                                                    this.state.loadingBaseFields
                                                }
                                                value={this.state.item.defField}
                                            >
                                                {this.state
                                                    .loadingBaseFields ? (
                                                    <option value={''}>
                                                        {this.props.kedo.t(
                                                            'Loading'
                                                        )}
                                                        ...
                                                    </option>
                                                ) : null}
                                                <option>
                                                    {kedo.t('Choose an option')}
                                                </option>
                                                <optgroup
                                                    label={kedo.t('Text')}
                                                >
                                                    {this.state.baseFields
                                                        .filter((item) =>
                                                            this.isTextType(
                                                                item
                                                            )
                                                        )
                                                        .map((item) => (
                                                            <option
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </optgroup>
                                                <optgroup
                                                    label={kedo.t('Date')}
                                                >
                                                    {this.state.baseFields
                                                        .filter(
                                                            (item) =>
                                                                item.type ===
                                                                'date'
                                                        )
                                                        .map((item) => (
                                                            <option
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </optgroup>
                                                <optgroup
                                                    label={this.props.kedo.t(
                                                        'List'
                                                    )}
                                                >
                                                    {this.state.baseFields
                                                        .filter(
                                                            (item) =>
                                                                item.type ===
                                                                    'list' ||
                                                                item.type ===
                                                                    'tree'
                                                        )
                                                        .map((item) => (
                                                            <option
                                                                key={item.id}
                                                                disabled={
                                                                    this.props
                                                                        .item &&
                                                                    this.props
                                                                        .item
                                                                        .name
                                                                }
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </optgroup>
                                                <optgroup
                                                    label={this.props.kedo.t(
                                                        'Other'
                                                    )}
                                                >
                                                    {this.state.baseFields
                                                        .filter(
                                                            (item) =>
                                                                !this.isTextType(
                                                                    item
                                                                ) &&
                                                                item.type !==
                                                                    'date' &&
                                                                item.type !==
                                                                    'list' &&
                                                                item.type !==
                                                                    'tree'
                                                        )
                                                        .map((item) => (
                                                            <option
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </optgroup>
                                            </Form.Control>
                                            {this.state.errors !== null &&
                                            this.state.errors.defField ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {this.state.errors.defField}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check
                                                checked={
                                                    this.state.item.obligatory
                                                }
                                                id={'checkbox-required-field'}
                                                label={this.props.kedo.t(
                                                    'Required'
                                                )}
                                                name={'obligatory'}
                                                onChange={this.handleCheck}
                                                type="checkbox"
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check
                                                checked={this.state.item.locked}
                                                id={'checkbox-required-lock'}
                                                label={this.props.kedo.t(
                                                    'Lock'
                                                )}
                                                name={'locked'}
                                                onChange={this.handleCheck}
                                                type="checkbox"
                                            />
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="misc">
                                        {this.getDefDossierDefFieldSettings(
                                            this.state.item
                                        )}
                                        {this.getCurrentDefFieldSetting(
                                            this.state.item.defField
                                        )}
                                    </Tab.Pane>
                                    {this.props.item && this.props.item.id ? (
                                        <Tab.Pane eventKey="info">
                                            <h3>{kedo.t('Info')}</h3>
                                            {this.state.infoItem ? (
                                                <ObjectCreatedInfo
                                                    kedo={kedo}
                                                    item={this.state.infoItem}
                                                />
                                            ) : null}
                                        </Tab.Pane>
                                    ) : null}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={this.state.locked}
                        onClick={this.submitDataField}
                        variant="primary"
                    >
                        {this.state.submitting ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {this.props.kedo.t('Save')}
                    </Button>
                    <Button
                        onClick={() => this.props.onClose()}
                        variant="secondary"
                    >
                        {this.props.kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DefDossierDefFieldForm
