import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import EnvironmentInfo from './EnvironmentInfo'
import AdminMainMenu from './AdminMainMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

import { faCopy } from '@fortawesome/pro-duotone-svg-icons/faCopy'
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList'
import { faPlusCircle } from '@fortawesome/pro-duotone-svg-icons/faPlusCircle'
import { faChevronCircleUp } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleUp'
import { faChevronCircleDown } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleDown'

const MainMenuItems = ({ kedo, closing }) => {
    const history = useHistory()
    const [main, setMain] = useState([])
    const [other, setOther] = useState([])
    const [hidden, setHidden] = useState([])
    const [showOther, setShowOther] = useState(false)
    const [showHidden, setShowHidden] = useState(false)
    const [active, setActive] = useState(() => {
        const saved = sessionStorage.getItem('activeEntity')
        const initialValue = JSON.parse(saved)
        return initialValue || ''
    })

    const clearMenu = () => {
        setMain([])
        setOther([])
        setHidden([])
    }

    const fetchMenuItems = () => {
        if (!kedo.env().getEnvironment()) {
            clearMenu()
            return
        }

        let mainItems = []
        let hiddenItems = []
        let otherItems = []

        let ddItems = kedo.env().getCurrentEnvironmentDefDossiers()
        if (ddItems && ddItems.filter) {
            ddItems = ddItems.filter((item) =>
                kedo.env().isAllowedDefDossier('show', item.id, kedo.user())
            )
            mainItems =
                ddItems && ddItems.filter
                    ? ddItems
                          .filter((item) => item.type === 'main')
                          .sort((a, b) => {
                              return a.rank - b.rank
                          })
                    : []
            hiddenItems =
                ddItems && ddItems.filter
                    ? ddItems
                          .filter((item) => item.type === 'hidden')
                          .sort((a, b) => {
                              return a.rank - b.rank
                          })
                    : []
            otherItems =
                ddItems && ddItems.filter
                    ? ddItems
                          .filter(
                              (item) =>
                                  item.type !== 'main' && item.type !== 'hidden'
                          )
                          .sort((a, b) => {
                              return a.rank - b.rank
                          })
                    : []
        }

        setMain(mainItems)
        setHidden(hiddenItems)
        setOther(otherItems)
    }

    const renderQueryItems = (ddItem, hidden) => {
        if (
            !ddItem ||
            !ddItem.query_settings ||
            !ddItem.query_settings.menu_queries
        ) {
            return null
        }

        return ddItem.query_settings.menu_queries
            .sort((a, b) => {
                return a.rank - b.rank
            })
            .map((queryItem) => (
                <NavLink
                    activeClassName="selectedLink"
                    className={`menu-item`}
                    key={`${ddItem.id}-${queryItem.queryUuid}`}
                    to={`/defdossier/${ddItem.id}?dossierQuery=${queryItem.queryUuid}`}
                >
                    <li
                        className={hidden ? `text-muted` : ''}
                        onClick={() => getResponsiveHandle(closing)}
                    >
                        {queryItem.name}
                    </li>
                </NavLink>
            ))
    }

    const getAllowedMenuItems = (filteredItems, hidden) => {
        if (!filteredItems || filteredItems.length <= 0) {
            return (
                <div className={'menu-item'}>
                    <li>
                        <small className={'text-muted'}>
                            {kedo.t('No items found')}
                        </small>
                    </li>
                </div>
            )
        }

        return filteredItems.map((item) => {
            let menuUrl = '/defdossier/' + item.id
            if (item.query_settings && item.query_settings.default_query) {
                menuUrl =
                    menuUrl +
                    '?dossierQuery=' +
                    item.query_settings.default_query
            }

            sessionStorage.setItem('activeEntity', JSON.stringify(active))

            return (
                <>
                    <NavLink
                        activeClassName="selectedLink"
                        className={
                            active === item.id
                                ? 'menu-item selectedLink'
                                : 'menu-item'
                        }
                        onClick={() => {
                            setActive(item.id)
                        }}
                        key={item.id}
                        to={menuUrl}
                    >
                        {
                            <li
                                className={hidden ? `text-muted` : ''}
                                onClick={() => getResponsiveHandle(closing)}
                            >
                                {kedo
                                    .env()
                                    .translateItem(item, 'defdossier', true)}
                            </li>
                        }
                    </NavLink>
                    {item.query_settings && item.query_settings.menu_queries
                        ? renderQueryItems(item, hidden)
                        : null}
                </>
            )
        })
    }

    const getResponsiveHandle = (close) => {
        window.scrollTo(0, 0)

        if (close && window.innerWidth <= 576) {
            return close()
        }
    }

    useEffect(() => {
        fetchMenuItems()
    }, [])

    if (!kedo.env().hasEnviroment()) {
        return <EnvironmentInfo kedo={kedo} />
    }

    return (
        <div>
            <EnvironmentInfo kedo={kedo} />
            <AdminMainMenu
                kedo={kedo}
                close={() => getResponsiveHandle(closing)}
            />
            <nav className="dropdown MainMenu">
                {kedo.isAdminOrEnvironmentAdmin() ? (
                    <div className={'menuHeadItem'}>
                        <FontAwesomeIcon icon={faCopy} />
                        &nbsp; {kedo.t('main_menu')}
                        <FontAwesomeIcon
                            onClick={() =>
                                history.push('/defdossier/edit/0', {
                                    id: 0,
                                    blub: (Date.now() % 1000) / 1000,
                                })
                            }
                            style={{ cursor: 'pointer', marginRight: '5px' }}
                            title={kedo.t('New entity')}
                            icon={faPlusCircle}
                            className={'addDossier float-right'}
                        />
                    </div>
                ) : (
                    <div className={'menuHeadItem'}>
                        <FontAwesomeIcon icon={faCopy} />
                        &nbsp; {kedo.t('main_menu')}
                    </div>
                )}
                <ul>
                    {kedo.env().hasModule('jwwmo') ? (
                        <NavLink
                            to={'/module/jwwmo/toewijzingen'}
                            activeClassName="selectedLink"
                            className={`menu-item`}
                            key={`mod-jwwmo`}
                            onClick={() => {
                                //Add link here
                            }}
                        >
                            <li>{kedo.t('Toewijzingen')}</li>
                        </NavLink>
                    ) : null}
                </ul>
                <ul>{getAllowedMenuItems(main)}</ul>
            </nav>
            {other.length > 0 ? (
                <nav className="dropdown MainMenu">
                    <div
                        onClick={() => setShowOther(!showOther)}
                        className={'menuHeadItem other'}
                    >
                        <FontAwesomeIcon icon={faList} />
                        &nbsp; {kedo.t('Other')}
                        <FontAwesomeIcon
                            icon={
                                showOther
                                    ? faChevronCircleUp
                                    : faChevronCircleDown
                            }
                            className={'dropArrow float-right'}
                        />
                    </div>
                    {showOther ? <ul>{getAllowedMenuItems(other)}</ul> : null}
                </nav>
            ) : null}
            {hidden.length > 0 && kedo.isAdminOrEnvironmentAdmin() ? (
                <nav className="dropdown MainMenu">
                    <div
                        onClick={() => setShowHidden(!showHidden)}
                        className={'menuHeadItem other'}
                    >
                        <FontAwesomeIcon icon={faList} />
                        &nbsp; {kedo.t('Hidden')}
                        <FontAwesomeIcon
                            icon={
                                showHidden
                                    ? faChevronCircleUp
                                    : faChevronCircleDown
                            }
                            className={'dropArrow float-right'}
                        />
                    </div>
                    {showHidden ? (
                        <ul>{getAllowedMenuItems(hidden, true)}</ul>
                    ) : null}
                </nav>
            ) : null}
        </div>
    )
}

export default MainMenuItems
