import React, { Component } from 'react'
import { Alert, Button, Modal, ProgressBar } from 'react-bootstrap'
import { faFileImport } from '@fortawesome/free-solid-svg-icons/faFileImport'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Applying extends Component {
    constructor(props) {
        super(props)

        let count = 0

        if (this.props.template.deffields) {
            count += this.props.template.deffields.length
        }

        if (this.props.template.defdossiers) {
            this.props.template.defdossiers.forEach((defDossier) => {
                if (defDossier.defdossierdeffields) {
                    count += defDossier.defdossierdeffields.length
                    defDossier.defdossierdeffields.forEach((dddf) => {
                        count += dddf.displayitems.length
                    })
                }
                if (defDossier.displaypositions) {
                    count += defDossier.displaypositions.length
                }
            })
        }

        if (this.props.template.defdossiers) {
            count += this.props.template.defdossiers.length
        }

        if (this.props.template.deffields) {
            this.props.template.deffields.forEach((defField) => {
                if (defField.type === 'list' && defField.def_field_lists) {
                    count += defField.def_field_lists.length
                }
            })
        }

        let template = JSON.parse(JSON.stringify(this.props.template))

        this.state = {
            loading: false,
            confirmed: false,
            manualConfirm: this.props.manualConfirm
                ? this.props.manualConfirm
                : false,
            running: false,
            showMsgs: false,
            finished: false,
            runMsg: [],
            environment: this.props.environment ? this.props.environment : null,
            applyCount: count,
            applyCountDone: 0,
            applying: template,
            messageError: [],
        }

        this.addRunMsg = this.addRunMsg.bind(this)
        this.loadingSpinner = this.loadingSpinner.bind(this)
        this.handleEnvironmentChange = this.handleEnvironmentChange.bind(this)
        this.onApply = this.onApply.bind(this)
        this.addCountDone = this.addCountDone.bind(this)
        this.addCountDone = this.addCountDone.bind(this)
        this.addOrUpdateItem = this.addOrUpdateItem.bind(this)
        this.addDefField = this.addDefField.bind(this)
    }

    fetchEnvironments() {
        let url = this.props.kedo.api().getEnvironmentEndpoint()
        let params = {}

        this.setState({ loading: true })

        this.props.kedo
            .api()
            .get(url, params)
            .then((response) =>
                this.setState({
                    loading: false,
                    environments: response.data.results,
                })
            )
            .catch((error) =>
                this.setState({
                    loading: false,
                    pager: {},
                })
            )
    }

    onApply() {
        this.setState({
            saving: true,
            applying: true,
        })
    }

    handleEnvironmentChange(event) {
        this.setState({ environment: event.target.value })
    }

    runInfo() {
        return this.loadingSpinner()
    }

    addRunMsg(msg, newLine = false) {
        this.state.runMsg.unshift({ msg: msg, newLine: newLine })

        this.setState({ runMsg: this.state.runMsg })
    }

    async addItem(url, data, updateCallBack) {
        const addResponse = await this.props.kedo.api().post(url, data)
        this.addRunMsg(
            'Toegevoegd ' + addResponse.data.id + ' ' + addResponse.data.uuid,
            true
        )
        if (updateCallBack) {
            updateCallBack()
        }
    }

    async updateItem(url, data, updateCallBack) {
        try {
            const updateResponse = await this.props.kedo.api().patch(url, data)
            this.addRunMsg('Aangepast', true)
            if (updateCallBack) {
                updateCallBack()
            }
        } catch (error) {
            this.setState((prevState) => ({
                messageError: [
                    ...prevState.messageError,
                    error.message,
                    error.response?.statusText,
                ],
            }))
        }
    }

    responseHasOneItem(response) {
        return response.data.pager.items === 1
    }

    async addDisplayItem(displayItem, dddf, ddl, defDossier) {
        const kedo = this.props.kedo
        let url = kedo.api().getDisplayItemEndpoint()
        let searchParams = {
            params: {
                environment: this.state.environment.id,
                uuid: displayItem.uuid,
            },
        }
        const response = await kedo.api().get(url, searchParams)
        this.addRunMsg('DisplayItem' + displayItem.uuid, true)

        let newData = {
            col: displayItem.col,
            colspan: displayItem.colspan,
            defDossier: defDossier.uuid,
            displayPosition: displayItem.displayposition,
            environment: this.state.environment.id,
            rank: displayItem.rank,
            rowspan: 0,
            translations: displayItem.translations,
            uuid: displayItem.uuid,
            view: displayItem.view,
            settings: displayItem.settings,
        }

        if (ddl) {
            newData.defDossierLink = ddl.uuid
        }
        if (dddf) {
            newData.defDossierDefField = dddf.uuid
        }

        await this.addOrUpdateItem(url, response, newData, newData)
    }

    async addDisplayItems(fields, dddf, ddl, defDossier) {
        if (!fields) {
            return
        }

        const forLoop = async (_) => {
            for (let index = 0; index < fields.length; index++) {
                await this.addDisplayItem(fields[index], dddf, ddl, defDossier)
            }
        }
        await forLoop()
    }

    async addDefDossierLink(objectLink, defDossier) {
        const kedo = this.props.kedo
        const url = kedo.api().getDefDossierLinkEndpoint()
        let searchParams = {
            params: {
                environment: this.state.environment.id,
                uuid: objectLink.uuid,
            },
        }
        const response = await kedo.api().get(url, searchParams)
        this.addRunMsg('DefDossierLink ' + objectLink.uuid, true)

        let newData = {
            environment: this.state.environment.id,
            uuid: objectLink.uuid,
            name: objectLink.name.replace(/\s+|[^a-zA-Z0-9]/g, '_'),
            defDossier: defDossier.uuid,
            childDefDossier: objectLink.child_dd_uuid,
            typeOfLink: objectLink.type_of_link,
            disable_backlink: true,
            obligatory: false,
            settings: {},
        }

        await this.addOrUpdateItem(url, response, newData, newData)
        await this.addDisplayItems(
            objectLink.displayitems,
            null,
            objectLink,
            defDossier
        )
    }

    async addDefDossierDefField(field, defDossier) {
        let url = this.props.kedo.api().getDefDossierDefFieldEndpoint()
        let searchParams = {
            params: {
                environment: this.state.environment.id,
                uuid: field.uuid,
            },
        }
        const response = await this.props.kedo.api().get(url, searchParams)
        this.addRunMsg('DefDossierDefField ' + field.uuid, true)
        var newData = {
            environment: this.state.environment.id,
            uuid: field.uuid,
            defDossier: defDossier.uuid,
            defField: field.deffield ? field.deffield.uuid : null,
            name: field.name.replace(/\s+|[^a-zA-Z0-9]/g, '_'),
        }

        this.addOrUpdateItem(url, response, newData, newData)
        this.addDisplayItems(field.displayitems, field, null, defDossier)
    }

    async addBacklinkDefDossierLink(field, defDossier) {
        const kedo = this.props.kedo
        const url = kedo.api().getUpdateBacklinkDefDossierLinkEndpoint()

        let newData = {
            environment: this.state.environment.id,
            uuid: field.uuid,
            backlinkUuid: field.backlink_uuid,
            defDossier: defDossier.uuid,
            childDefDossier: field.child_dd_uuid,
        }

        const response = await kedo.api().patch(url, newData)

        this.setState((prevState) => ({
            applyCountDone: prevState.applyCountDone + 1,
        }))
    }

    async addBacklinkDefDossierLinks(fields, defDossier) {
        const forLoop = async (_) => {
            for (let index = 0; index < fields.length; index++) {
                await this.addBacklinkDefDossierLink(fields[index], defDossier)
            }
        }
        await forLoop()
    }

    async addDefDossierLinks(objectLinks, defDossier) {
        const forLoop = async (_) => {
            for (let index = 0; index < objectLinks.length; index++) {
                await this.addDefDossierLink(objectLinks[index], defDossier)
            }
        }
        await forLoop()
    }

    async addDefDossierDefFields(fields, defDossier) {
        const forLoop = async (_) => {
            for (let index = 0; index < fields.length; index++) {
                await this.addDefDossierDefField(fields[index], defDossier)
            }
        }
        await forLoop()
    }

    async addDefDossier(defDossier, index, totals) {
        const api = this.props.kedo.api()
        const url = api.getDefDossierEndpoint()

        let searchParams = {
            params: {
                environment: this.state.environment.id,
                uuid: defDossier.uuid,
            },
        }
        const response = await api.getCached(url, searchParams)

        this.addRunMsg('DefDossier ' + defDossier.uuid, true)
        let newData = {
            environment: this.state.environment.id,
            uuid: defDossier.uuid,
            type:
                defDossier.type === 'hourregistration'
                    ? 'list'
                    : defDossier.type,
            name: defDossier.name,
            translations: defDossier.translations,
            description: defDossier.description,
            rank: defDossier.rank ? defDossier.rank : 0,
        }

        await this.addOrUpdateItem(
            url,
            response,
            newData,
            newData,
            async () => {
                const kedo = this.props.kedo
                if (defDossier.displaypositions) {
                    const dpUrl = kedo.api().getDisplayPositionEndpoint()
                    const dpForLoop = async (_) => {
                        for (
                            let dpIndex = 0;
                            dpIndex < defDossier.displaypositions.length;
                            dpIndex++
                        ) {
                            let dpItem = defDossier.displaypositions[dpIndex]
                            let dpSearchParams = {
                                params: {
                                    environment: this.state.environment.id,
                                    uuid: dpItem.uuid,
                                },
                            }
                            let dpResponse = await api.get(
                                dpUrl,
                                dpSearchParams
                            )
                            let newDpData = {
                                environment: this.state.environment.id,
                                defDossier: defDossier.uuid,
                                uuid: dpItem.uuid,
                                type:
                                    dpItem.type === 'unknown'
                                        ? 'footer'
                                        : dpItem.type,
                                rank: dpItem.rank,
                                translations:
                                    dpItem.translations.length > 0
                                        ? dpItem.translations
                                        : [
                                              {
                                                  culture: 'en_EN',
                                                  name_singular: dpItem.uuid,
                                                  name_plural: dpItem.uuid,
                                              },
                                          ],
                            }

                            if (
                                dpIndex + 1 ===
                                defDossier.displaypositions.length
                            ) {
                                this.addOrUpdateItem(
                                    dpUrl,
                                    dpResponse,
                                    newDpData,
                                    newDpData,
                                    async () => {
                                        await this.addDefDossierDefFields(
                                            defDossier.defdossierdeffields,
                                            defDossier
                                        )
                                    }
                                )
                            } else {
                                await this.addOrUpdateItem(
                                    dpUrl,
                                    dpResponse,
                                    newDpData,
                                    newDpData
                                )
                            }
                        }
                    }
                    await dpForLoop()
                } else {
                    await this.addDefDossierDefFields(
                        defDossier.defdossierdeffields,
                        defDossier
                    )
                }

                if (totals - 1 === index) {
                    this.addRunMsg('Adding DefDossierLinks')
                    const ddDflForLoop = async (_) => {
                        for (
                            let ddDflIndex = 0;
                            ddDflIndex < this.state.applying.defdossiers.length;
                            ddDflIndex++
                        ) {
                            await this.addDefDossierLinks(
                                this.state.applying.defdossiers[ddDflIndex]
                                    .defdossierlinks,
                                this.state.applying.defdossiers[ddDflIndex]
                            )
                        }
                    }
                    await ddDflForLoop()
                    const ddBacklinkDflForLoop = async (_) => {
                        for (
                            let backlinkDdDflIndex = 0;
                            backlinkDdDflIndex <
                            this.state.applying.defdossiers.length;
                            backlinkDdDflIndex++
                        ) {
                            await this.addBacklinkDefDossierLinks(
                                this.state.applying.defdossiers[
                                    backlinkDdDflIndex
                                ].defdossierlinks,
                                this.state.applying.defdossiers[
                                    backlinkDdDflIndex
                                ]
                            )
                        }
                    }

                    await ddBacklinkDflForLoop()
                }
            }
        )
    }

    async addOrUpdateItem(url, response, addData, updateData, updateCallBack) {
        this.setState((prevState) => ({
            applyCountDone: prevState.applyCountDone + 1,
        }))

        if (!this.responseHasOneItem(response)) {
            await this.addItem(url, addData, updateCallBack)
        } else {
            await this.updateItem(
                url + '/' + response.data.results[0].id,
                updateData,
                updateCallBack
            )
        }
    }

    addCountDone(typeField) {
        let currentCount = this.state[typeField]
        this.setState({ [typeField]: currentCount + 1 })
    }

    async addDefFieldLists(defFieldList, index) {
        let defFieldListUrl = this.props.kedo.api().getDefFieldListEndpoint()
        let searchParams = {
            params: {
                environment: this.state.environment.id,
                uuid: defFieldList.uuid,
            },
        }
        const response = await this.props.kedo
            .api()
            .get(defFieldListUrl, searchParams)

        this.addRunMsg('DefFieldList ' + defFieldList.uuid, true)
        let newData = {
            environment: this.state.environment.id,
            uuid: defFieldList.uuid,
            rank: defFieldList.rank ? defFieldList.rank : 0,
            defField: defFieldList.defField,
            translations: defFieldList.translations,
            color: defFieldList.color,
        }

        await this.addOrUpdateItem(defFieldListUrl, response, newData, newData)
    }

    async addDefField(defField, index) {
        let defFieldUrl = this.props.kedo.api().getDefFieldEndpoint()
        let searchParams = {
            params: {
                environment: this.state.environment.id,
                uuid: defField.uuid,
            },
        }
        const response = await this.props.kedo
            .api()
            .get(defFieldUrl, searchParams)
        this.addRunMsg('DefField ' + defField.uuid, true)
        let newData = {
            environment: this.state.environment.id,
            uuid: defField.uuid,
            type: defField.type,
            name: defField.name,
        }
        await this.addOrUpdateItem(
            defFieldUrl,
            response,
            newData,
            newData,
            async () => {
                if (defField.type === 'list') {
                    const dflForLoop = async (_) => {
                        for (
                            let dflIndex = 0;
                            dflIndex < defField.def_field_lists.length;
                            dflIndex++
                        ) {
                            defField.def_field_lists[dflIndex].defField =
                                defField.uuid
                            await this.addDefFieldLists(
                                defField.def_field_lists[dflIndex],
                                dflIndex
                            )
                        }
                    }
                    await dflForLoop()
                }
                if (index + 1 === this.state.applying.deffields.length) {
                    await this.addDefDossiers()
                }
            }
        )
    }

    async addDefFields() {
        const forLoop = async (_) => {
            for (
                let index = 0;
                index < this.state.applying.deffields.length;
                index++
            ) {
                await this.addDefField(
                    this.state.applying.deffields[index],
                    index
                )
            }
        }
        await forLoop()
    }

    async addDefDossiers() {
        let totals = this.state.applying.defdossiers.length
        const forLoop = async (_) => {
            for (let index = 0; index < totals; index++) {
                await this.addDefDossier(
                    this.state.applying.defdossiers[index],
                    index,
                    totals
                )
            }
        }
        await forLoop()
    }

    process() {
        this.setState({
            confirmed: true,
            running: true,
        })

        //Start applying
        this.addDefFields()
    }

    loadingSpinner() {
        if (!this.state.environment) {
            return (
                <Alert variant={'warning'}>
                    {this.props.kedo.t('Select an environment first')}
                </Alert>
            )
        }

        let env = this.state.environment
        let progress = parseInt(
            (this.state.applyCountDone / this.state.applyCount) * 100
        )

        return (
            <div
                style={{
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'center',
                    margin: 10,
                }}
            >
                <p className={'text-center'}>
                    {this.state.showMsgs ? (
                        <p style={{ maxHeight: 200, overflow: 'scroll' }}>
                            {this.state.runMsg.map((item, iIndex) => (
                                <span key={iIndex}>
                                    {item.msg} {item.newLine ? <br /> : null}
                                </span>
                            ))}
                        </p>
                    ) : null}
                    <strong>
                        {this.props.templateName
                            ? this.props.templateName
                            : this.props.kedo.t('Template')}{' '}
                        {this.props.kedo.t('is being applied to')} {env.name}.
                    </strong>
                </p>
                <ProgressBar variant={'success'} animated now={progress} />
                <br />
                {this.props.showButtons && progress === 100 ? (
                    <div className={'text-center'}>
                        <Button
                            variant={'primary'}
                            onClick={() => this.setEnvironment(env)}
                        >
                            {this.props.kedo.t('Go to')} {env.name}
                        </Button>{' '}
                        &nbsp;
                        <Button
                            variant={'secondary'}
                            onClick={() => this.props.backToEdit()}
                        >
                            {this.props.kedo.t('Close')}
                        </Button>
                    </div>
                ) : null}
                {this.state.messageError.length > 0 ? (
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
                            ERROR
                        </p>
                        {this.state.messageError
                            .slice(
                                Math.max(this.state.messageError.length - 2, 0)
                            )
                            .map((errorMessage, index) => (
                                <p key={index}>
                                    <strong>
                                        {this.props.kedo.t(errorMessage)}
                                    </strong>
                                </p>
                            ))}
                    </div>
                ) : null}
            </div>
        )
    }

    setEnvironment(env) {
        if (!env) {
            return
        }

        this.props.kedo
            .api()
            .get(this.props.kedo.api().getEnvironmentEndpoint() + '/' + env.id)
            .then((response) => {
                this.props.kedo.env().setEnvironment(response.data)
            })

        setTimeout(() => (window.location.href = '/'), 200)
        window.scrollTo(0, 0)
    }

    confirm() {
        if (
            this.state.environment?.locales[0].code ===
            this.props.environment?.locales[0].code
        ) {
            return (
                <div>
                    <Modal.Body>
                        <p>
                            {this.props.kedo.t('Apply on')}{' '}
                            <strong>{this.state.environment.name}</strong>?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant={'primary'}
                            onClick={() => this.process()}
                        >
                            <FontAwesomeIcon icon={faFileImport} /> &nbsp;{' '}
                            {this.props.kedo.t('Apply')}
                        </Button>
                        <Button
                            variant={'secondary'}
                            onClick={this.props.backToEdit}
                            title={this.props.kedo.t('Cancel')}
                        >
                            {this.props.kedo.t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </div>
            )
        } else {
            return (
                <div>
                    <Modal.Body>
                        <p>
                            {this.props.kedo.t('The environment')}
                            <strong>
                                &nbsp;{this.state.environment.name}&nbsp;
                            </strong>
                            {this.props.kedo.t('do not have the same language')}
                            <strong>
                                &nbsp;{this.props.environmentMain.name}.
                            </strong>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant={'secondary'}
                            onClick={this.props.backToEdit}
                            title={this.props.kedo.t('Close')}
                        >
                            {this.props.kedo.t('Close')}
                        </Button>
                    </Modal.Footer>
                </div>
            )
        }
    }

    async componentDidMount() {
        let countLink = this.state.applyCount
        let objectLinks = this.state.applying.defdossiers
        let defDossiers = this.state.applying.defdossiers

        for (
            let ddDflIndex = 0;
            ddDflIndex < objectLinks.length;
            ddDflIndex++
        ) {
            const defDossier = objectLinks[ddDflIndex]
            const defDossierLinks = defDossier.defdossierlinks

            countLink += defDossierLinks.length * 2

            this.setState({
                applyCount: countLink,
            })
        }

        for (
            let backlinkDdDflIndex = 0;
            backlinkDdDflIndex < defDossiers.length;
            backlinkDdDflIndex++
        ) {
            const defDossier = defDossiers[backlinkDdDflIndex]
            const fields = defDossier.defdossierlinks

            countLink += fields.length

            this.setState({
                applyCount: countLink,
            })
        }
    }

    render() {
        if (this.state.manualConfirm && this.state.environment) {
            if (this.state.applyCount === this.state.applyCountDone) {
                this.props.onClose()
                return <div />
            }

            if (this.props.noModal && true === this.props.noModal) {
                return (
                    <>
                        {!this.state.running ? this.process() : null}
                        {this.state.running ? this.runInfo() : null}
                    </>
                )
            }

            return (
                <Modal size={'lg'} show={true}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.props.kedo.t('Busy fetching the page')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!this.state.running ? this.process() : null}
                        {this.state.running ? this.runInfo() : null}
                    </Modal.Body>
                </Modal>
            )
        }

        return (
            <div>
                {this.state.environment && !this.state.confirmed
                    ? this.confirm()
                    : null}
                {this.state.running ? this.runInfo() : null}
            </div>
        )
    }
}

export default Applying
