import React, { Component } from 'react'
import { Alert, Col, Nav, Row, Tab } from 'react-bootstrap'
import LoadModuleContent from '../LoadModuleContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/pro-duotone-svg-icons/faFolder'
import DefaultGrid from '../../../Grid/DefaultGrid'
import AvatarGroup from '../../../Elements/AvatarGroup'

class DefaultPageTemplate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            displayPositions: this.props.displayPositions,
            content: this.props.content,
            edit: false,
            id: this.props.id,
            layout_sidebar:
                this.props.defdossier &&
                this.props.defdossier.settings &&
                this.props.defdossier.settings.layout_sidebar
                    ? this.props.defdossier.settings.layout_sidebar
                    : null,
        }
    }

    renderAlertArchive() {
        return (
            <Alert key={'archivewarning'} variant={'info'}>
                <FontAwesomeIcon icon={faFolder} />
                &nbsp; {this.props.kedo.t('This item is archived')}
            </Alert>
        )
    }

    renderFooter(displayPosition) {
        if (
            !displayPosition ||
            !displayPosition.display_items ||
            displayPosition.display_items.length <= 0
        ) {
            return ''
        } else {
            return (
                <div>
                    <hr className={'contentDossier'} />
                    <Row>
                        <Col xs={12}>
                            {this.props.getDisplayPositionContent(
                                displayPosition,
                                false,
                                true
                            )}
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    getContentByView(displayContents, displayItems, viewName) {
        let returnContent = {}

        let filterItems = displayItems.filter(
            (displayItem) => displayItem.view === viewName
        )

        if (filterItems.length >= 0) {
            filterItems.map((displayItem) => {
                let find = displayContents.find(
                    (content) => content.content.id == displayItem.id
                )
                find
                    ? (returnContent[displayItem.id] = find.content.content)
                    : null
            })
        }

        return returnContent
    }

    renderSidebar(displayPositions, activeTab) {
        const kedo = this.props.kedo
        if (displayPositions && displayPositions.length <= 0) {
            return null
        }

        const view = isNaN(this.state.layout_sidebar)
            ? this.state.layout_sidebar
            : 'show/edit'

        return (
            <>
                <div>
                    <Row>
                        <Col
                            style={{
                                borderLeft:
                                    this.props.modalized &&
                                    window.innerWidth > 576
                                        ? '1px solid #dee2e6'
                                        : null,
                            }}
                            xs={12}
                        >
                            <DefaultGrid
                                key={this.props.dossier.id}
                                defDossierSettings={
                                    this.props.defdossier &&
                                    this.props.defdossier.settings
                                        ? this.props.defdossier.settings
                                        : null
                                }
                                conditions={
                                    this.props.defdossier &&
                                    this.props.defdossier.settings &&
                                    this.props.defdossier.settings.conditions
                                        ? this.props.defdossier.settings
                                              .conditions
                                        : []
                                }
                                content={this.getContentByView(
                                    this.props.sidebarContent.filter(
                                        (contentItem) =>
                                            contentItem.id ===
                                            this.props.dossier.id
                                    ),
                                    this.props.sidebarDisplayItems,
                                    view
                                )}
                                dossier={this.props.dossier}
                                items={this.props.sidebarDisplayItems.filter(
                                    (displayItem) => displayItem.view === view
                                )}
                                kedo={this.props.kedo}
                                mode={
                                    isNaN(this.state.layout_sidebar)
                                        ? this.state.layout_sidebar
                                        : 'show'
                                }
                                withoutEdit={true}
                            />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    renderTabs(displayPositions, activeTab) {
        const kedo = this.props.kedo
        if (displayPositions && displayPositions.length <= 0) {
            throw new Error(this.props.kedo.t('404_general_message'))
        }

        let mainPosition = displayPositions.find(function (item) {
            return item.type === 'main_content'
        })

        let metadataPosition = displayPositions.find(function (item) {
            return item.type === 'meta'
        })

        let positionsFiltered = displayPositions.filter(
            (position) =>
                position.type === 'tab' || position.type === 'main_content'
        )

        return (
            <Tab.Container
                onSelect={this.props.handleTabSelect}
                id="contentdossier"
                defaultActiveKey={
                    activeTab && parseInt(activeTab) > 0
                        ? parseInt(activeTab)
                        : mainPosition.id
                }
            >
                <Nav variant="tabs" className={'contentDossierNav'}>
                    {positionsFiltered
                        .filter((item) => !item.hide && item.hide !== true)
                        .map((item) => (
                            <Nav.Item key={item.id}>
                                <Nav.Link
                                    eventKey={item.id}
                                    className={
                                        item.type === 'main_content'
                                            ? 'mainLink'
                                            : ''
                                    }
                                >
                                    {kedo
                                        .env()
                                        .translateItem(
                                            item,
                                            'displayposition',
                                            true
                                        )}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                </Nav>
                <Tab.Content>
                    {positionsFiltered
                        .filter((item) => !item.hide && item.hide !== true)
                        .map((tabPosition) => (
                            <Tab.Pane
                                className={
                                    tabPosition.type === 'main_content'
                                        ? 'primaryTab contentDossierTab'
                                        : 'subTab'
                                }
                                eventKey={tabPosition.id}
                                key={tabPosition.id}
                            >
                                <div className="tabCD" id={tabPosition.id}>
                                    {this.renderMetadata(metadataPosition)}
                                    <Row className="tabCD-content">
                                        <Col xs={12}>
                                            <h3>
                                                {tabPosition.translations.find(
                                                    (lang) =>
                                                        lang.culture ===
                                                        kedo.getCulture()
                                                )
                                                    ? tabPosition.translations.find(
                                                          (lang) =>
                                                              lang.culture ===
                                                              kedo.getCulture()
                                                      ).name_plural
                                                    : tabPosition.name_plural}
                                            </h3>
                                            {(!activeTab &&
                                                tabPosition.type ===
                                                    'main_content') ||
                                            parseInt(activeTab) ===
                                                tabPosition.id
                                                ? this.props.getDisplayPositionContent(
                                                      tabPosition,
                                                      this.props.isAllowedEditTab(
                                                          tabPosition
                                                      )
                                                  )
                                                : kedo.t('No tab selected')}
                                            {(!activeTab &&
                                                tabPosition.type ===
                                                    'main_content') ||
                                            (parseInt(activeTab) ===
                                                tabPosition.id &&
                                                this.props.loadModule) ? (
                                                <LoadModuleContent
                                                    dossierId={this.props.id}
                                                    module={
                                                        this.props.loadModule
                                                    }
                                                    kedo={kedo}
                                                />
                                            ) : null}
                                        </Col>
                                    </Row>
                                </div>
                            </Tab.Pane>
                        ))}
                </Tab.Content>
            </Tab.Container>
        )
    }

    renderMetadata(metadataPosition) {
        if (
            !metadataPosition ||
            !metadataPosition.display_items ||
            metadataPosition.display_items.length <= 0
        ) {
            return ''
        } else {
            return (
                <div className="contentDossierMetadata">
                    <Row>
                        <Col xs={12}>
                            {this.props.getDisplayPositionContent(
                                metadataPosition,
                                false,
                                true
                            )}
                        </Col>
                    </Row>
                    <hr className={'contentDossier'} />
                </div>
            )
        }
    }

    render() {
        return (
            <Row>
                <Col xs={12} md={this.state.layout_sidebar ? 9 : null}>
                    {this.props.showExisting.length > 0
                        ? this.props.renderExisting()
                        : null}
                    <div className="content-section">
                        <div className={'mainContainer'}>
                            {!this.props.modalized
                                ? this.props.getGeneralHeader()
                                : null}
                            <div>
                                <AvatarGroup
                                    users={this.props.socketUsers}
                                    kedo={this.props.kedo}
                                />
                            </div>
                            {this.props.dossier && this.props.dossier.archived
                                ? this.renderAlertArchive()
                                : null}
                            {this.props.showSaveSuccess
                                ? this.renderSuccessAlert()
                                : null}
                            {!this.props.modalized ? <br /> : null}
                            {this.renderTabs(
                                this.props.displayPositions,
                                this.props.activeTabKey
                            )}
                            {this.renderFooter(
                                this.props.displayPositions.find(
                                    (item) => item.type === 'footer'
                                )
                            )}
                        </div>
                    </div>
                </Col>
                {this.state.layout_sidebar ? (
                    <Col xs={12} md={3}>
                        <div className="content-section">
                            <div className={'mainContainer'}>
                                {this.renderSidebar(
                                    this.props.displayPositions,
                                    this.props.activeTabKey
                                )}
                            </div>
                        </div>
                    </Col>
                ) : null}
            </Row>
        )
    }
}

export default DefaultPageTemplate
