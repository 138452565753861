import React from 'react'
import { Alert, Button, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import Loading from '../../Elements/Loading/LoadingData'
import Pagination from '../../Elements/Pagination'
import ItemCounter from '../../Elements/ItemCounter'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'

class ContentDossierLog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            logs: [],
            searchType: null,
            searchUser: null,
            page: 0,
            pager: [],
            loading: false,
        }

        this.fetchData = this.fetchData.bind(this)
        this.getUserSearch = this.getUserSearch.bind(this)
        this.getTypeSearch = this.getTypeSearch.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
        this.setSearch = this.setSearch.bind(this)
    }

    onChangePage(number) {
        this.setState({ page: number }, this.fetchData)
    }

    fetchData() {
        let api = this.props.kedo.api()
        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                page: this.state.page,
                dossier: this.props.dossier.id,
            },
        }

        if (!this.props.kedo.env().hasEnviroment()) {
            this.setState({
                error: this.props.kedo.t('No environment selected'),
            })
            return
        }

        if (this.state.searchUser !== null) {
            params.params.user = this.state.searchUser
        }

        if (
            this.state.searchType !== null &&
            this.state.searchType.length > 0
        ) {
            params.params.type = this.state.searchType
        }

        this.setState({ loading: true })
        api.get(api.getDossierLogEndpoint(), params).then((response) =>
            this.setState({
                logs: response.data.results,
                pager: response.data.pager,
                loading: false,
            })
        )
    }

    getChangedData(item) {
        let changes = JSON.parse(item.changes)

        if (item.type === 'change' && changes.length > 0) {
            return (
                <ul>
                    {changes.map((changeItem, changeIndex) => (
                        <li key={changeIndex}>
                            <strong>{changeItem.defDisplayItemName}</strong>
                            <br />
                            <s>{changeItem.oldValue}</s> <br />
                            {changeItem.newValue} <br />
                        </li>
                    ))}
                </ul>
            )
        }

        if (item.type === 'archive' && changes.length > 0) {
            return (
                <ul>
                    {changes.map((changeItem, changeIndex) => (
                        <li key={changeIndex}>{changeItem.newValue}</li>
                    ))}
                </ul>
            )
        }

        if (item.type === 'delete' && changes.length > 0) {
            return (
                <strong className={'text-danger'}>
                    {this.props.kedo.t('Deleted')}
                </strong>
            )
        }

        return (
            <small className={'text-muted'}>
                {this.props.kedo.t('No changes')}
            </small>
        )
    }

    fetchUsers() {
        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
            },
        }

        this.setState({ loadUsers: true })
        this.props.kedo
            .api()
            .get(this.props.kedo.api().getUserEndpoint(), params)
            .then((response) => {
                this.setState({
                    users: response.data.results,
                    loadUsers: false,
                })
            })
    }

    getUserSearch() {
        let selectOptions = []
        if (this.state.users && this.state.users.length > 0) {
            this.state.users.forEach((item) => {
                selectOptions.push({
                    value: item.id,
                    label:
                        item.username +
                        ' (' +
                        item.profile.first_name +
                        ' ' +
                        item.profile.last_name +
                        ')',
                })
            })
        }

        return (
            <Select
                isClearable
                name={'Select User Dossierlog'}
                noOptionsMessage={() => this.props.kedo.t('No users found')}
                onChange={(item) =>
                    this.setSearch('searchUser', item ? item : null)
                }
                options={selectOptions}
            />
        )
    }

    getTypeSearch() {
        let selectOptions = [
            { value: 'shown', label: this.props.kedo.t('log_types.shown') },
            { value: 'change', label: this.props.kedo.t('log_types.change') },
            { value: 'archive', label: this.props.kedo.t('log_types.archive') },
            { value: 'delete', label: this.props.kedo.t('log_types.delete') },
        ]

        return (
            <Select
                isClearable
                name={'Select Type Dossierlog'}
                noOptionsMessage={() => this.props.kedo.t('No users found')}
                onChange={(item) =>
                    this.setSearch('searchType', item ? item : null)
                }
                options={selectOptions}
            />
        )
    }

    setSearch(type, item) {
        this.setState({ [type]: item ? item.value : null }, this.fetchData)
    }

    translateType(type) {
        if (type === 'archive') {
            return this.props.kedo.t('log_types.archive')
        }
        if (type === 'change') {
            return this.props.kedo.t('log_types.change')
        }
        if (type === 'delete') {
            return this.props.kedo.t('log_types.delete')
        }
        if (type === 'shown') {
            return this.props.kedo.t('log_types.shown')
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchUsers()
    }

    render() {
        const kedo = this.props.kedo

        return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={() => this.props.onClose()}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {kedo.t('Logs')}: {this.props.dossier.summary}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <h5>{kedo.t('Search')}</h5>
                            <Form.Group>
                                <Form.Label>{kedo.t('Type')}</Form.Label>
                                {this.getTypeSearch()}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{kedo.t('Users')}</Form.Label>
                                {this.getUserSearch()}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>{kedo.t('Date')}</th>
                                <th>{kedo.t('Type')}</th>
                                <th>{kedo.t('User')}</th>
                                <th>{kedo.t('Changes')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.loading ? (
                                <tr>
                                    <td colSpan={4}>
                                        <Loading />
                                    </td>
                                </tr>
                            ) : this.state.logs &&
                              this.state.logs.length > 0 ? (
                                this.state.logs.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                {kedo
                                                    .utils()
                                                    .dateFormat(item.datetime)}
                                            </td>
                                            <td>
                                                {this.translateType(item.type)}
                                            </td>
                                            <td>
                                                <a
                                                    href={`/user/${item.user_id}`}
                                                >
                                                    {item.username
                                                        ? item.username
                                                        : item.user_id}
                                                </a>
                                            </td>
                                            <td>{this.getChangedData(item)}</td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={4}>
                                        <Alert variant={'info'}>
                                            <FontAwesomeIcon
                                                icon={faExclamationCircle}
                                            />
                                            &nbsp; {kedo.t('No items found')}
                                        </Alert>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={4}>
                                    <Pagination
                                        pager={this.state.pager}
                                        onChangePage={this.onChangePage}
                                    />
                                    <ItemCounter pager={this.state.pager} />
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => this.props.onClose()}
                        variant="secondary"
                        title={kedo.t('Close')}
                    >
                        {kedo.t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ContentDossierLog
